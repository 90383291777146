import React from 'react';
import { formatCurrency, formatPercent, numbersNoFormat } from '../helpers/utils';

export function CurrencyInput({
  name,
  label,
  value,
  onChange,
  error,
  ...props
}) {
  
  const captureInput = e => {
    onChange({ target: { name, value: e.target.value} });
  };

  const formatValue = e => {
    if(value === undefined || value === null) { return; }
    let removeFormat = numbersNoFormat(value);
    onChange({ target: { name, value: formatCurrency(removeFormat)} });
  }

  return (
    <div className="mb-2">
      <label className="form-label">{label}</label>
      <input
        className={`form-control ${error && 'is-invalid'}`}
        {...props}
        id={name}
        name={name}
        placeholder='$0'
        type="text"
        value={value}
        onChange={captureInput}
        onBlur={formatValue}
      />
      {error && <div className="form-text invalid-feedback">{error}</div>}
    </div>
  );
}

export function PercentInput({
  name,
  label,
  value,
  onChange,
  error,
  ...props
}) {
  
  const captureInput = e => {
    onChange({ target: { name, value: e.target.value} });
  };

  const formatValue = e => {
    if(value === undefined || value === null) { return; }
    let removeFormat = numbersNoFormat(value);
    onChange({ target: { name, value: formatPercent(removeFormat)} });
  }

  return (
    <div className="mb-2">
      <label className="form-label">{label}</label>
      <input
        className={`form-control ${error && 'is-invalid'}`}
        {...props}
        id={name}
        name={name}
        type="text"
        value={value}
        onChange={captureInput}
        onBlur={formatValue}
      />
      {error && <div className="form-text invalid-feedback">{error}</div>}
    </div>
  );
}

export function InputWithError({
  name,
  label,
  value,
  error,
  ...props
}) {
  if(error) {console.log(label + " " + error); }
  return (
    <div className="mb-2">
      <label className="form-label">{label}</label>
      <input
        className={`form-control ${error && 'is-invalid'}`}
        {...props}
        id={name}
        name={name}
        type="text"
        value={value}
      />
      {error && <div className="form-text invalid-feedback">{error}</div>}
    </div>
  );
}