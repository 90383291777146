import {
  Body,
  Verify,
  Task,
  Email,
  SignOff,
  AutoCheck,
  FindLabelFromName,
  TableZero,
} from './FileDetailsHelpers';
import LoginState from '../LoginState';
import React, { Component } from 'react';
import axios from 'axios';
import { PRICING_OPTIONS, STATUS_COLORS } from '../../helpers/constants';
import Toast from '../Toast';
import { todaysDateYYYY, getPropertyType, isAdmin } from '../../helpers/utils';
import ModalEmail from './ModalEmail';
import ModalEmailLog from './ModalEmailLog';
import ModalEmailVerification from './ModalEmailVerification';
import ModalConfirm from '../ModalConfirm';
import ChecklistCard from './ChecklistCard';

export default class SubmissionChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: this.props.id,
      fileData: [],
      ltvFico: [],
      borrowers: [],
      isLoading: true,
      errorMessage: '',
      checkList: {
        changeInSubmission: { checked: false },
        investorFileNo: { checked: false },
        criticalDates: { checked: false },
        demographics: { checked: false },
        email: { checked: false },
        index: { checked: false },
        fileAssignments: { checked: false },
        relatedParties: { checked: false },
        escrowFileNo: { checked: false },
        titleFileNo: { checked: false },
        companyCloud: { checked: false },
        changeSubmitted: { checked: false },
        notifyTeam: { checked: false },
        complete: { checked: false },
      },
      showEmailTemplate: false,
      emailTemplate: [],
      submittedTemplate: [],
      submittedEmailLog: [],
      showEmailVerification: false,
      verificationEmail: '',
    };
    this.getSubmissionData();
  }

  async getSubmissionData() {
    var response = axios.get('/getloansummary', {
      params: { pFileID: this.state.activeFile },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;

    response = axios.get('/getchecklistdata', {
      params: { pFileID: this.state.activeFile, pChecklistType: 'SUB' },
    });
    // the response.data will contain the values returned
    var checkData = (await response).data;
    var checklistData = this.state.checkList;
    for (var i = 0; i < checkData.length; i++) {
      checklistData[checkData[i].fieldName] = {
        checked: checkData[i].isChecked === 'True',
        timestamp: checkData[i].timestamp,
        userName: checkData[i].userName,
      };
    }

    var intPropType = parseInt(resultData.propertyType);
    if (parseInt(resultData.numberOfUnits) > 1) {
      resultData.propertyType = resultData.numberOfUnits + ' Unit';
    } else {
      resultData.propertyType = getPropertyType(intPropType);
    }

    switch (resultData.loanPurpose) {
      case 'PUR':
        resultData.loanPurpose = 'Purchase';
        break;
      case 'R/T':
        resultData.loanPurpose = 'R/T Refinance';
        break;
      default:
        resultData.loanPurpose = 'C/O Refinance';
        break;
    }

    try {
      resultData.occupancyType =
        PRICING_OPTIONS.occupancyType[
          parseInt(resultData.occupancyType) - 1
        ].label;
    } catch {
      resultData.occupancyType = '? OCCUPANCY TYPE ?';
    }

    response = axios.get('/getsubmissionfields', {
      params: {
        pFileID: this.state.activeFile,
      },
    });

    var submissionFields = (await response).data;
    this.setState({
      fileData: resultData,
      checkList: checklistData,
      invFileExists: submissionFields.invFileExists === '1' || false,
      investorFileNo: submissionFields.investorFileNo || '',
      originalInvestorFileNo: submissionFields.investorFileNo || '',
      escrowNo: submissionFields.escrowAccountNo || '',
      originalEscrowNo: submissionFields.escrowAccountNo || '',
      titleNo: submissionFields.titleAccountNo || '',
      originalTitleNo: submissionFields.titleAccountNo || '',
      submittedTemplate: submissionFields.submittedTemplate,
      submittedEmailLog: submissionFields.submittedEmailLog,
      borrowers: submissionFields.borrowers,
      isLoading: false,
    });
  }

  async setChecklistField(fieldName, checked) {
    axios.get('/setchecklistfield', {
      params: {
        pFileID: this.state.activeFile,
        pFieldName: 'SUB-' + fieldName,
        pIsChecked: checked,
        pUserName: LoginState.userName,
      },
    });
  }

  captureInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _toggleCheckbox = ({ target: { name, checked } }) => {
    if (checked) {
      this.setState({ confirmTitle: name, showConfirmModal: true });
    } else if (isAdmin()) {
      this.setState({ confirmTitle: name, showClearModal: true });
    }
  };

  setCheckbox(checked) {
    const name = this.state.confirmTitle;
    const { checkList } = this.state;
    const fieldItem = checkList[name];
    fieldItem.checked = checked;
    fieldItem.timestamp = new Date()
      .toLocaleString()
      .split(/\D/)
      .slice(0, 3)
      .map(num => num.padStart(2, '0'))
      .join('/');
    fieldItem.userName = LoginState.userName;
    this.setState({
      checkList: checkList,
      showConfirmModal: false,
      showClearModal: false,
    });
    this.setChecklistField(name, checked);
    if (!checked) {
      checkList.complete.checked = false;
      this.setChecklistField('complete', false);
    }
  }

  captureInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Updated']: false,
    });
  };

  // fieldsToUpdate [tableName, fieldName, dataValue, name, checkboxName, conditions]
  async updateField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatefield', {
        params: {
          pFileID: this.state.activeFile,
          pTableName: fieldsToUpdate[i][0], // tableName
          pFieldName: fieldsToUpdate[i][1], // fieldName
          pValue: fieldsToUpdate[i][2], // dataValue
          pConditions: fieldsToUpdate[i][5], // conditions
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        this.setState({
          ['original' +
          fieldsToUpdate[i][3].substring(0, 1).toUpperCase() +
          fieldsToUpdate[i][3].substring(1)]: fieldsToUpdate[i][2],
          [fieldsToUpdate[i][3] + 'Updated']: true,
        });
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][4]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][4], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  // fieldsToUpdate [fieldName, docID, dataValue, checkboxName, stateField]
  async updateDynamicDataField(fieldsToUpdate) {
    for (var i = 0; i < fieldsToUpdate.length; i++) {
      var response = axios.get('/updatedynamicfield', {
        params: {
          pFileID: this.state.activeFile,
          pList: 'SUB',
          pFieldName: fieldsToUpdate[i][0], // fieldName
          pDocID: fieldsToUpdate[i][1], // docID
          pDataValue: fieldsToUpdate[i][2].toString().trim(), // dataValue
        },
      });
      var resultData = (await response).data;
      if (resultData === 'Success') {
        if (fieldsToUpdate[i][4]) {
          this.setState({
            ['original' +
            fieldsToUpdate[i][4].substring(0, 1).toUpperCase() +
            fieldsToUpdate[i][4].substring(1)]: this.state[fieldsToUpdate[i][4]]
              .toString()
              .trim(),
            [fieldsToUpdate[i][4] + 'Updated']: true,
          });
        }
        // if we cleared / empty string
        if (!fieldsToUpdate[i][2] && fieldsToUpdate[i][3]) {
          var checkList = this.state.checkList;
          checkList[fieldsToUpdate[i][3]].checked = false;
          this.setState({ checkList: checkList });
          this.setChecklistField(fieldsToUpdate[i][3], false);
        }
      } else {
        this.setState({ errorMessage: 'Error: Database Not Updated!' });
      }
    }
  }

  async setLoanStatus(status) {
    this.setState({ changingState: status });
    var response = axios.get('/updateLoanStatus', {
      params: {
        pFileID: this.state.activeFile,
        pLoanStatus: status,
        pLoanPurpose: this.state.fileData.loanPurpose,
      },
    });
    var resultData = (await response).data;
    if (resultData === 'Success') {
      var file = this.state.fileData;
      file.loanStatus = status;
      switch (status) {
        case 'In Submission':
          file.inSubmissionDate = todaysDateYYYY();
          break;
        case 'Reviewed':
          file.reviewedDate = todaysDateYYYY();
          break;
        case 'Approved':
          file.approvedDate = todaysDateYYYY();
          break;
        case 'Submitted':
          file.submittedDate = todaysDateYYYY();
          break;
        default:
          break;
      }
      this.setState({ fileData: file, changingState: false });
    } else {
      this.setState({ errorMessage: resultData, changingState: false });
    }
  }

  allChecked() {
    var visibleCount = 0;
    var checkedCount = 0;
    var checklistData = this.state.checkList;
    for (var prop in checklistData) {
      if (
        prop === 'complete' ||
        prop === 'changeReviewed' ||
        prop === 'changeApproved' ||
        prop === 'creditReport'
      )
        continue;
      // if (
      //   prop === 'investorPortal' &&
      //   !this.state.fileData.programCode.includes('MTGP2')
      // )
      //   continue;
      if (
        prop === 'relatedParties' &&
        this.state.fileData.loanPurpose !== 'Purchase'
      )
        continue;

      visibleCount++;
      if (prop === 'changeInSubmission') {
        if (this.state.fileData.inSubmissionDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (prop === 'changeSubmitted') {
        if (this.state.fileData.submittedDate !== '---') {
          checkedCount++;
        }
        continue;
      }
      if (
        Object.prototype.hasOwnProperty.call(checklistData, prop) &&
        checklistData[prop].checked
      )
        checkedCount++;
    }
    var completePercent = this.state.fileData.completePercent
      ?.split('SUB')[1]
      .split('(')[1]
      .split(')')[0];
    if (
      completePercent.toString() !==
      Math.round((checkedCount / visibleCount) * 100).toString()
    ) {
      this.state.fileData.completePercent =
        this.state.fileData.completePercent?.split('SUB')[0] +
        'SUB (' +
        Math.round((checkedCount / visibleCount) * 100).toString() +
        ')' +
        this.state.fileData.completePercent
          ?.split('SUB')[1]
          .substring(
            this.state.fileData.completePercent?.split('SUB')[1].indexOf(')') +
              1
          );
      this.props.updatePercent(
        'SUB',
        Math.round((checkedCount / visibleCount) * 100).toString()
      );
    }
    if (checkedCount < visibleCount) return false;
    return true;
  }

  markComplete() {
    axios.get('/SubmissionChecklistComplete', {
      params: { pFileID: this.state.activeFile },
    });
    this.setChecklistField('complete', true);
    var cl = this.state.checkList;
    cl.complete.checked = true;
    this.setState({ checkList: cl });
    this.props.updatePercent('SUB', 'complete');
  }

  render() {
    const { isLoading, fileData, checkList } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <h1 className="h5">Submission Checklist</h1>
          <Body>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <img
                src="../images/spinner.gif"
                alt=""
                height="64px"
                width="64px"
              />
            </div>
          </Body>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="h5">Submission Checklist</h1>
        <div>
          <h6>
            {fileData.programCode} + {fileData.loanPurpose} +{' '}
            {fileData.occupancyType} + {fileData.propertyType}
          </h6>
          <p className="text-secondary">
            (Please Check All Information Below For Spelling and Accuracy)
          </p>

          {/* Change Status (In Submission) */}
          <ChecklistCard
            checked={this.state.fileData.inSubmissionDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeInSubmission"
                  name="changeInSubmission"
                  disabled={true}
                  checked={this.state.fileData.inSubmissionDate !== '---'}
                />
                <label
                  className="form-check-label"
                  htmlFor="changeInSubmission"
                >
                  In Submission
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'In Submission'" />
                {this.state.fileData.inSubmissionDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['in submission'],
                      }}
                    >
                      In Submission
                    </span>
                    {this.state.fileData.inSubmissionDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.inSubmissionDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('In Submission')}
                    >
                      {this.state.changingState === 'In Submission'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.inSubmissionDate !== '---'}
                tip="In Submission Date Not Found"
              />
            }
          />

          {/* Critical Dates */}
          <ChecklistCard
            checked={checkList.criticalDates.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="criticalDates"
                  name="criticalDates"
                  checked={checkList.criticalDates.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="criticalDates">
                  Critical Dates
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Verify tip="BytePro → Home Page → Application Date → Click Circle Dot" />
                  Application Date
                </div>
                <div>
                  <Verify tip="BytePro → Home Page → Set Date to the Date After the Application Date (24 Hr Turn Time)" />
                  Scheduled Approval Date
                </div>
                <div>
                  <Verify
                    tip={
                      fileData.loanPurpose === 'Purchase' ? (
                        <span>
                          COE: Date Listed in Est. Sett. Stmt. or EI
                          <br />
                          Funding: 1 Day Before COE (Exclude Weekends)
                          <br />
                          Closing: 2 Days Before Funding Date
                        </span>
                      ) : (
                        <span>
                          Funding: ~4 Weeks After Application Date
                          <br />
                          Closing: 2 Days Before Funding
                          <br />
                          (If Rescission Exists, Count 2 Days Before Funding -
                          Including Saturdays)
                          <br />
                        </span>
                      )
                    }
                  />
                  Scheduled Closing Date
                </div>
              </div>
            }
            alert={
              checkList.criticalDates.checked && (
                <SignOff
                  user={checkList.criticalDates.userName}
                  date={checkList.criticalDates.timestamp}
                />
              )
            }
          />

          {/* Demographics */}
          <ChecklistCard
            checked={checkList.demographics.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="demographics"
                  name="demographics"
                  checked={checkList.demographics.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="demographics">
                  Demographics
                </label>
              </div>
            }
            body={
              <div>
                <Task
                  tip={
                    <span>
                      Fill With Information 'Provided by Borrower'
                      <br />
                      Otherwise Fill Out to the 'Best of Your Knowledge'
                    </span>
                  }
                />
                Fill Out Fields
              </div>
            }
            alert={
              checkList.demographics.checked && (
                <SignOff
                  user={checkList.demographics.userName}
                  date={checkList.demographics.timestamp}
                />
              )
            }
          />

          {/* Email */}
          <ChecklistCard
            checked={checkList.email.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="email"
                  name="email"
                  checked={checkList.email.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="email">
                  Email
                </label>
              </div>
            }
            body={
              <TableZero>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'top' }}>
                      <Verify tip="Click Borrower Links Below to Verify Emails Not Already Used For A Different Borrower" />
                    </td>
                    <td>
                      <div style={{ marginBottom: '10px' }}>
                        Borrower Email Verification
                      </div>
                      {this.state.borrowers.map(borrower => (
                        <div
                          key={borrower.name}
                          style={{ marginBottom: '5px' }}
                        >
                          <button
                            className="btn btn-sm btn-outline-primary mr-2"
                            disabled={checkList.email.checked}
                            onClick={() =>
                              this.setState({
                                showEmailVerification: true,
                                verificationEmail: borrower.email,
                              })
                            }
                          >
                            {borrower.name} ({borrower.email})
                          </button>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </TableZero>
            }
            alert={
              checkList.email.checked && (
                <SignOff
                  user={checkList.email.userName}
                  date={checkList.email.timestamp}
                />
              )
            }
          />

          {/* Index */}
          <ChecklistCard
            checked={checkList.index.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="index"
                  name="index"
                  checked={checkList.index.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="index">
                  Index
                </label>
              </div>
            }
            body={
              <div className="vstack gap-3">
                <div>
                  <Task
                    tip={
                      <span>
                        Will Likely Need to Save Docs on Desktop to Separate
                        <br />
                        After Organizing Package, Upload to Byte (Drag and Drop)
                        <br />
                        If Received Seller Docs, Category Will Be Under 'PROP'
                        <br />
                      </span>
                    }
                  />
                  Index Uploaded Submission Package
                </div>
                <div>
                  <Task />
                  Employment Info, AOI, and/or SOI
                </div>
                <div>
                  <Task />
                  Property Profiles, Subject, and REOs (if any)
                </div>
              </div>
            }
            alert={
              checkList.index.checked && (
                <SignOff
                  user={checkList.index.userName}
                  date={checkList.index.timestamp}
                />
              )
            }
          />

          {/* File Assignment */}
          <ChecklistCard
            checked={checkList.fileAssignments.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="fileAssignments"
                  name="fileAssignments"
                  checked={checkList.fileAssignments.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="fileAssignments">
                  File Assignment
                </label>
              </div>
            }
            body={
              <div className="hstack gap-1">
                <Task />
                Confirm UW Assignee
              </div>
            }
            alert={
              checkList.fileAssignments.checked && (
                <SignOff
                  user={checkList.fileAssignments.userName}
                  date={checkList.fileAssignments.timestamp}
                />
              )
            }
          />
          {/* Related Parties */}
          {fileData.loanPurpose === 'Purchase' && (
            <ChecklistCard
              checked={checkList.relatedParties.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="relatedParties"
                    name="relatedParties"
                    checked={checkList.relatedParties.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="relatedParties">
                    Related Parties
                  </label>
                </div>
              }
              body={
                <div className="hstack gap-1">
                  <Task />
                  Input All Parties' Info, Including Seller's
                </div>
              }
              alert={
                checkList.relatedParties.checked && (
                  <SignOff
                    user={checkList.relatedParties.userName}
                    date={checkList.relatedParties.timestamp}
                  />
                )
              }
            />
          )}

          {/* EscrowFileNo */}
          <ChecklistCard
            checked={checkList.escrowFileNo.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  name="escrowFileNo"
                  id="escrowFileNo"
                  disabled={!this.state.originalEscrowNo}
                  checked={checkList.escrowFileNo.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="escrowFileNo">
                  Escrow
                </label>
              </div>
            }
            body={
              <div className="input-group">
                <span className="input-group-text">File #</span>
                <input
                  type="text"
                  className="form-control"
                  name="escrowNo"
                  value={this.state.escrowNo || ''}
                  onChange={this.captureInput}
                  disabled={checkList.escrowFileNo.checked}
                />
                <button
                  className="btn btn-primary btn-sm"
                  disabled={
                    checkList.escrowFileNo.checked ||
                    this.state.originalEscrowNo === this.state.escrowNo
                  }
                  onClick={() => {
                    this.updateField([
                      [
                        'PartyMisc',
                        'EscrowAccountNo',
                        this.state.escrowNo,
                        'escrowNo',
                        'escrowFileNo',
                      ],
                      [
                        'HUD1',
                        'FileNo',
                        this.state.escrowNo,
                        'escrowNo',
                        'escrowFileNo',
                      ],
                    ]);
                    this.props.updateNumber('Escrow', this.state.escrowNo);
                  }}
                >
                  {this.state.escrowNoUpdated ? '✓' : 'Update'}
                </button>
              </div>
            }
            alert={
              checkList.escrowFileNo.checked && (
                <SignOff
                  user={checkList.escrowFileNo.userName}
                  date={checkList.escrowFileNo.timestamp}
                />
              )
            }
          />

          {/* TitleFileNo */}
          <ChecklistCard
            checked={checkList.titleFileNo.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                  id="titleFileNo"
                  name="titleFileNo"
                  disabled={!this.state.originalTitleNo}
                  checked={checkList.titleFileNo.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="titleFileNo">
                  Title
                </label>
              </div>
            }
            body={
              <div className="input-group">
                <span className="input-group-text">Title #</span>
                <input
                  type="text"
                  className="form-control"
                  name="titleNo"
                  value={this.state.titleNo || ''}
                  onChange={this.captureInput}
                  disabled={checkList.titleFileNo.checked}
                />
                <button
                  className="btn btn-primary btn-sm"
                  disabled={
                    this.state.originalTitleNo === this.state.titleNo ||
                    checkList.titleFileNo.checked
                  }
                  onClick={() => {
                    this.updateField([
                      [
                        'PartyMisc',
                        'TitleAccountNo',
                        this.state.titleNo,
                        'titleNo',
                        'titleFileNo',
                      ],
                    ]);
                    this.props.updateNumber('Title', this.state.titleNo);
                  }}
                >
                  {this.state.titleNoUpdated ? '✓' : 'Update'}
                </button>
              </div>
            }
            alert={
              checkList.titleFileNo.checked && (
                <SignOff
                  user={checkList.titleFileNo.userName}
                  date={checkList.titleFileNo.timestamp}
                />
              )
            }
          />

          {/* Company Cloud */}
          <ChecklistCard
            checked={checkList.companyCloud.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="companyCloud"
                  name="companyCloud"
                  checked={checkList.companyCloud.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="companyCloud">
                  Company Cloud
                </label>
              </div>
            }
            body={
              <div>
                <Task
                  tip={
                    <span>
                      Go Into "1 Files" and Copy the "Template" Folder
                      <br />
                      Go Into "1 In Progress", Paste the Copied Folder
                      <br />
                      Rename the Folder to the Loan # of the New File
                      <br />
                      Go Into the "13 Related Documents" Sub-Folder
                      <br />
                      Rename the Excel Workbook, Adding "_File's Loan #"
                      <br />
                      (E.g. Workbook_300-00-12345)
                    </span>
                  }
                />
                Add File Folder to the Company Cloud
              </div>
            }
            alert={
              checkList.companyCloud.checked && (
                <SignOff
                  user={checkList.companyCloud.userName}
                  date={checkList.companyCloud.timestamp}
                />
              )
            }
          />

          {/* Investor Portal */}
          {/* {fileData.programCode.includes('MTGP2') && (
            <ChecklistCard
              checked={checkList.investorPortal.checked}
              label={
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    aria-label="Checkbox for following text input"
                    id="investorPortal"
                    name="investorPortal"
                    checked={checkList.investorPortal.checked}
                    onChange={this._toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="investorPortal">
                    Investor Portal
                  </label>
                </div>
              }
              body={
                <div className="vstack gap-3">
                  <div>
                    <Task />
                    Register on Investor Side
                  </div>
                </div>
              }
              alert={
                checkList.investorPortal.checked && (
                  <SignOff
                    user={checkList.investorPortal.userName}
                    date={checkList.investorPortal.timestamp}
                  />
                )
              }
            />
          )} */}

          {/* Investor Portal Number */}
          <ChecklistCard
            checked={checkList.investorFileNo.checked}
            label={
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="investorFileNo"
                  id="investorFileNo"
                  disabled={
                    (!this.state.originalInvestorFileNo &&
                      this.state.invFileExists) ||
                    this.state.investorFileNo !==
                      this.state.originalInvestorFileNo
                  }
                  checked={checkList.investorFileNo.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="investorFileNo">
                  Investor Portal
                </label>
              </div>
            }
            body={
              <TableZero>
                <tbody>
                  <tr>
                    <td>
                      <span
                        style={{
                          display: 'inline-block',
                          paddingRight: '15px',
                        }}
                      >
                        Investor File Number:
                      </span>
                      <div className="form-check form-check-inline">
                        <label
                          htmlFor="invFileExists1"
                          className="form-check-label"
                        >
                          <input
                            disabled={checkList.investorFileNo.checked}
                            className="form-check-input"
                            type="radio"
                            id="invFileExists1"
                            name="invFileExists1"
                            checked={this.state.invFileExists}
                            onChange={() => void 0}
                            onClick={() => {
                              this.setState({ invFileExists: true });
                              this.updateDynamicDataField([
                                ['InvFileExists', '', '1', '', ''],
                              ]);
                            }}
                          />
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label
                          htmlFor="invFileExists2"
                          className="form-check-label"
                        >
                          <input
                            disabled={checkList.investorFileNo.checked}
                            className="form-check-input"
                            type="radio"
                            id="invFileExists2"
                            name="invFileExists2"
                            checked={!this.state.invFileExists}
                            onChange={() => void 0}
                            onClick={() => {
                              this.setState({ invFileExists: false });
                              this.updateDynamicDataField([
                                ['InvFileExists', '', '0', '', ''],
                              ]);
                            }}
                          />
                          No
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: '10px' }}>
                      {this.state.invFileExists && (
                        <div className="input-group">
                          <span className="input-group-text">File #:</span>
                          <input
                            type="text"
                            name="investorFileNo"
                            className="form-control"
                            value={this.state.investorFileNo || ''}
                            onChange={this.captureInput}
                            disabled={checkList.investorFileNo.checked}
                          />
                          <button
                            className="btn btn-primary btn-sm"
                            disabled={
                              this.state.originalInvestorFileNo ===
                                this.state.investorFileNo ||
                              checkList.investorFileNo.checked
                            }
                            onClick={() =>
                              this.updateDynamicDataField([
                                [
                                  'InvestorFileNo',
                                  '',
                                  this.state.investorFileNo,
                                  'investorFileNo',
                                  'investorFileNo',
                                ],
                              ])
                            }
                          >
                            {this.state.investorFileNoUpdated ? '✓' : 'Update'}
                          </button>{' '}
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </TableZero>
            }
            alert={
              checkList.investorFileNo.checked && (
                <SignOff
                  user={checkList.investorFileNo.userName}
                  date={checkList.investorFileNo.timestamp}
                />
              )
            }
          />

          {/* Change Status (Submitted) */}
          <ChecklistCard
            checked={this.state.fileData.submittedDate !== '---'}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="changeSubmitted"
                  name="changeSubmitted"
                  disabled={true}
                  checked={this.state.fileData.submittedDate !== '---'}
                />
                <label className="form-check-label" htmlFor="changeSubmitted">
                  Submitted
                </label>
              </div>
            }
            body={
              <div>
                <Verify tip="Change Loan Status to 'Submitted'" />
                {this.state.fileData.submittedDate !== '---' ? (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: 'black',
                        backgroundColor: STATUS_COLORS['submitted'],
                      }}
                    >
                      Submitted
                    </span>
                    {this.state.fileData.submittedDate}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span
                      className={`fw-normal badge border rounded-pill`}
                      style={{
                        marginRight: '8px',
                        color: [
                          'clear to close',
                          'clear to fund',
                          'in post-funding',
                          'on hold',
                        ].includes(this.state.fileData.loanStatus.toLowerCase())
                          ? 'white'
                          : 'black',
                        backgroundColor:
                          STATUS_COLORS[
                            this.state.fileData.loanStatus.toLowerCase()
                          ],
                      }}
                    >
                      {this.state.fileData.loanStatus}
                    </span>
                    <button
                      className="btn btn-sm btn-outline-primary ms-2"
                      style={{ width: '65px' }}
                      disabled={
                        this.state.changingState ||
                        this.state.fileData.submittedDate !== '---'
                      }
                      onClick={() => this.setLoanStatus('Submitted')}
                    >
                      {this.state.changingState === 'Submitted'
                        ? '...'
                        : 'Change'}
                    </button>
                  </React.Fragment>
                )}
              </div>
            }
            alert={
              <AutoCheck
                passed={this.state.fileData.submittedDate !== '---'}
                tip="Submitted Date Not Found"
              />
            }
          />

          {/* Notify Team */}
          <ChecklistCard
            checked={checkList.notifyTeam.checked}
            label={
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="Checkbox for following text input"
                  id="notifyTeam"
                  name="notifyTeam"
                  checked={checkList.notifyTeam.checked}
                  onChange={this._toggleCheckbox}
                />
                <label className="form-check-label" htmlFor="notifyTeam">
                  Send Email
                </label>
              </div>
            }
            body={
              <div>
                <Email />
                Submitted
                <button
                  className="btn btn-sm btn-outline-primary ms-2"
                  onClick={() => {
                    this.setState({
                      showEmailTemplate: true,
                      emailTemplate: this.state.submittedTemplate,
                      emailLog: this.state.submittedEmailLog,
                    });
                  }}
                >
                  Preview
                </button>
                {this.state.submittedEmailLog.length > 0 && (
                  <button
                    className="btn"
                    type="button"
                    style={{
                      height: '24px',
                      maxWidth: '24px',
                      marginLeft: '10px',
                      pointerEvents: 'auto',
                    }}
                    onClick={() =>
                      this.setState({
                        showEmailLog: true,
                        emailLog: this.state.submittedEmailLog,
                      })
                    }
                  >
                    <i
                      className="bi bi-envelope-check"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                      }}
                    />
                  </button>
                )}
              </div>
            }
            alert={
              checkList.notifyTeam.checked && (
                <SignOff
                  user={checkList.notifyTeam.userName}
                  date={checkList.notifyTeam.timestamp}
                />
              )
            }
          />

          {this.state.checkList.complete.checked && this.allChecked() ? (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                style={{ backgroundColor: '#84c488', borderColor: 'gray' }}
                disabled={true}
              >
                Completed ✓
              </button>
            </div>
          ) : (
            <div className="text-center mt-4">
              <button
                className="btn btn-primary text-white"
                onClick={() => this.markComplete()}
                disabled={
                  !this.allChecked() || this.state.checkList.complete.checked
                }
              >
                {this.allChecked() ? 'Mark Complete' : 'Not Completed'}
              </button>
            </div>
          )}

          {this.state.errorMessage && (
            <Toast
              type="danger"
              title={this.state.errorMessage}
              onClose={() => this.setState({ errorMessage: '' })}
            />
          )}

          <ModalEmail
            show={this.state.showEmailTemplate}
            template={this.state.emailTemplate}
            fileID={this.state.activeFile}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
            onAccept={() =>
              this.setState({ showEmailTemplate: false, emailTemplate: [] })
            }
          />
          <ModalEmailVerification
            show={this.state.showEmailVerification}
            email={this.state.verificationEmail}
            onDecline={() =>
              this.setState({
                showEmailVerification: false,
                verificationEmail: '',
              })
            }
          />
          <ModalEmailLog
            show={this.state.showEmailLog}
            emailLog={this.state.emailLog}
            onDecline={() =>
              this.setState({ showEmailLog: false, emailLog: [] })
            }
          />
          <ModalConfirm
            show={this.state.showConfirmModal || this.state.showClearModal}
            onAccept={() => this.setCheckbox(this.state.showConfirmModal)}
            onDecline={() =>
              this.setState({ showConfirmModal: false, showClearModal: false })
            }
          >
            <div style={{ fontSize: '16px' }}>
              <b>{this.state.showConfirmModal ? 'Set' : 'Clear'} Checkbox:</b>
              <span style={{ marginLeft: '10px' }}>
                "{FindLabelFromName(this.state.confirmTitle)}"
              </span>
            </div>
          </ModalConfirm>
        </div>
      </React.Fragment>
    );
  }
}
