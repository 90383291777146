import React, { useState } from 'react';

export default function ProgramList(props) {
  const [selectedCode, setSelectedCode] = useState(null);
  if (!props.availablePrograms) {
    return (
      <div className="text-center">
        <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
      </div>
    );
  } else if (props.availablePrograms.length === 0) {
    return (
      <div className="text-center">
        <h5 className="mt-4 mb-2" style={{ whiteSpace: 'pre-wrap' }}>
          {props.progErr ? props.progErr : 'UH OH!'}
        </h5>
        <h5 style={{color:'red'}}>No programs available!</h5>
      </div>
    );
  } else {
    return (
      <table className="table">
        <tbody
          style={{
            display: 'block',
            overflow: 'auto',
            maxHeight: '300px'
          }}
        >
          {props.availablePrograms.map(program => 
            {
              const selected = program.code === selectedCode || (props.selectedProgram && program.code === props.selectedProgram.code);
              return (
                <tr
                  className={`border bg-white p-3 rounded align-items-center 
                    ${selected && 'border-primary border-3'}`}
                  key={`${program.code}-${program.displayRate}`}
                  style={{ cursor: 'pointer'}}
                  onClick={() => {
                    setSelectedCode(program.code);
                    props.onSelect(program);
                  }}
                >
                  <td width="150px">
                    <h5 className="fw-bold text-uppercase mt-2">
                      {program.code}
                    </h5>
                  </td>
                  <td width="400px">
                    <p className="mt-2">{program.name}</p>
                  </td>
                  <td width="225px">
                    {program.displayRate && (
                      <React.Fragment>
                        <i className="bi bi-check text-success"></i>
                        <span className="fs-6 text-success">
                          ({program.displayRate}&#37; PAR)
                        </span>
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    );
  }
}
