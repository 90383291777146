import { CurrencyInput, PercentInput, InputWithError } from '../components/CurrencyInput';
import Modal from '../components/Modal';
import React, { Component } from 'react';
import Adjustments from '../components/Adjustments';
import LoginState from '../components/LoginState';
import { PRICING_OPTIONS } from '../helpers/constants';
import ProgramList from '../components/ProgramList';
import ProgramRates from '../components/ProgramRates';
import Toast from '../components/Toast';
import axios from 'axios';
import { formatCurrency, numbersNoFormat } from '../helpers/utils';

const Placeholder = () => (
  <div className="mb-2 d-none d-lg-block">
    <label htmlFor="help-input-placeholder" className="form-label">
      &nbsp;
    </label>
    <input
      type="text"
      disabled
      readOnly
      className="form-control-plaintext"
      id="inputPlaceholder"
      value=""
    ></input>
    <div id="help-input-placeholder" className="form-text invalid-feedback">
      &nbsp;
    </div>
  </div>
);

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.programsRef = React.createRef();
    this.state = {
      loading: false,
      displayPrograms: false,
      autoFillDisplay: false,
      successMsg: '',
      autoFillList: [
        // { label: 'Default (Empty)', value: 0 },
        // { label: 'Standard Filled Out', value: 1 },
      ],
      errors: {},
      noProgramReason: '',
      rateAdjustments: [],
      priceAdjustments: [],
      fieldsUserDisplay: {},
      lienPosition: 1,
      documentType: 1, // Full Documentation
      foreignNational: 2, // No
      incomeVerification: 1,
      monthsReserve: 2, // 6 Months (change to 12 if loan over $1 million)
      prepayPenalty: 1, // no prepay penalty
      loanPurpose: 1,
      loanProduct: 1,
      propertyType: 1,
      occupancyType: 1,
      zipCode: '',
      impound: 1,
      pmi: 1,
      lockPeriod: 1,
      county: "Los Angeles",
      state: "California",
      stateAbbr: "CA",
      retrievedData: false,
      showRateSheet: false,
      timeStamp: '',
      rateSheetList: [],
    };

    this.fields = [
      'lienPosition',
      'documentType',
      'creditScore',
      'dti',
      'purchasePrice',
      'firstLoan',
      'secondLoan',
      'ltv',
      'cltv',
      'foreignNational',
      'incomeVerification',
      'monthsReserve',
    ];

    this.CheckRateSuspended(false);
  }

  componentDidMount() {
    if (this.state.autoFillList.length === 0) {
      // get options before page loads
      this.getAutoFillOptions();
    }
  }

  async CheckRateSuspended(getPrograms) {
    axios.get('/IsRateSuspended').then(res => {
      if (res && res.data) { this.setState({ rateSuspend: res.data === "True" }); }
      if(res.data !== "True" && getPrograms) {
        this.getProgramInformation();        
      }
    });    
  }

  ShowLoadingMsg() {
    return <img src="/images/spinner.gif" alt="" height="64px" width="64px" />;
  }

  /**
   * Capture any inputs value, save it in state
   */
  captureInput = e => {
    var propValueChanging = this.state.propValueChanging || false;

    // update ltv
    if (['firstLoan', 'secondLoan', 'purchasePrice'].includes(e.target.name)) {
      this.updateLtv(e.target.name, e.target.value);
      propValueChanging = false;
    }

    // if ltv/cltv updated, update loan amounts
    if (['ltv', 'cltv'].includes(e.target.name) ) {
      if(parseFloat(numbersNoFormat(this.state.firstLoan)) > 0 && (propValueChanging || 
        (Number(this.state.purchasePrice) === Number.NaN || parseFloat(numbersNoFormat(this.state.purchasePrice)) === 0))) {
        this.updatePurchasePrice(e.target.name, numbersNoFormat(e.target.value)); 
        propValueChanging = true;
      }
      else {
        this.updateLoanAmounts(e.target.name, numbersNoFormat(e.target.value));
        propValueChanging = false;
      }
    }
    if (e.target.name === 'zipCode') {
      this.checkCounty(e.target.value);
    }

    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.name];
      this.setState({
        propValueChanging: propValueChanging,
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      this.setState({
        propValueChanging: propValueChanging,
        [e.target.name]: e.target.value,
      });
      if (e.target.name === 'documentType') {
        this.setState({ loanProduct: '1' });
      }
    }
  };

  async checkCounty(zip) {
    if(zip.toString().length !== 5) {
      if(zip.toString().length === 0)
        this.setState({stateAbbr: "CA", state: "California", county: "Los Angeles"});
      else
        this.setState({stateAbbr: "CA", state: "---", county: "---"});
      return;
    }
    axios.get('/GetCounty', { params: { pZipCode: zip}}).then(res => {
      if (res && res.data) { 
        var addData = res.data.split(',');
        if(addData.length === 3)
          this.setState({stateAbbr: addData[0], state: addData[1], county: addData[2]});
        else
          this.setState({stateAbbr: "CA", state: "---", county: "---"});
      }
    });
  }

  /*
  / this function will be called when the purchasePrice,
  / or firstLoan values are updated and will auto-update the ltv 
  / value that will be calculated.
  */
  updateLtv = (updatedField, updatedValue) => {
    // setState does not update values right away and so if I call the values
    // from state, I realized after debugging that it returns the previous
    // state's values rather then the updated ones.
    // we need to retrive the values directly to avoid this error.
    const { purchasePrice } = this.state;

    var firstLoan =
      updatedField === 'firstLoan' ? updatedValue : this.state.firstLoan;
    if (firstLoan) {
      firstLoan = numbersNoFormat(firstLoan);
    }

    var secondLoan =
      updatedField === 'secondLoan' ? updatedValue : this.state.secondLoan;
    if (secondLoan) {
      secondLoan = numbersNoFormat(secondLoan);
    }

    var purPri =
      updatedField === 'purchasePrice'
        ? updatedValue
        : purchasePrice
        ? purchasePrice
        : Number.MAX_SAFE_INTEGER;
    if (purPri) {
      purPri = numbersNoFormat(purPri);
    }

    // first check to make sure each of the fields have values
    if (!firstLoan || purPri === Number.MAX_SAFE_INTEGER) return;

    // if ltv or cltv exceeds 100%, leave blank
    if (firstLoan > purPri) {
      this.setState({ ltv: '', cltv: '' });
      return;
    }

    // set ltv value
    const vLtv = Math.round(
      (parseInt(firstLoan) / purPri) * 10000
    ) / 100;

    // get first+second loans
    var loanTotal = parseInt(firstLoan);
    if (secondLoan) loanTotal += parseInt(secondLoan);

    // set ltv value
    const vCltv = Math.round((loanTotal / purPri) * 10000) / 100;
    
    this.setState({
      ltv: vLtv ? vLtv.toFixed(2) + '%' : '',
      cltv: vCltv ? vCltv.toFixed(2) + '%' : '',
    });
  };

  /*
  / this function will be called when the purchasePrice,
  / or firstLoan values are updated and will auto-update the ltv 
  / value that will be calculated.
  */
  updateLoanAmounts = (updatedField, updatedValue) => {
    const { purchasePrice } = this.state;
    let purPri = purchasePrice ? numbersNoFormat(purchasePrice) : Number.MAX_SAFE_INTEGER;

    // first check to make sure we have some value set for either the property value or purchase price
    if (purPri === Number.MAX_SAFE_INTEGER) return;

    // also make sure ltv/cltv is set to a value
    if ((updatedField === 'ltv' || updatedField === 'cltv') && (isNaN(updatedValue) || isNaN(parseFloat(updatedValue)) || numbersNoFormat(updatedValue) <= 0)) {
      this.setState({ firstLoan: '', ltv: '---', cltv: '---'});
      return;
    }

    // if lien position is 2nd only, also return
    if (parseInt(this.state.lienPosition) === 2) return;

    // if ltv or cltv exceeds 100%, leave blank
    if (parseFloat(updatedValue) > 100) {
      this.setState({ firstLoan: '', secondLoan: '' });
      return;
    }

    // set first loan amount based on ltv
    const vFirstLoan = (parseFloat(updatedField === 'ltv' ? updatedValue : numbersNoFormat(this.state.ltv)) * purPri) / 100;

    // set second loan amount if we have both a first and second loan
    const vTotalLoan =
      parseInt(this.state.lienPosition) !== 3
        ? this.state.secondLoan
        : (parseFloat(updatedField === 'cltv' ? updatedValue : this.state.cltv) * purPri) / 100;
    const vSecondLoan = parseInt(vTotalLoan) - parseInt(vFirstLoan);
    this.setState({
      firstLoan: formatCurrency(vFirstLoan),
      secondLoan: vSecondLoan >= 0 ? formatCurrency(vSecondLoan) : '',
      ltv: (updatedField === 'ltv' || parseInt(this.state.lienPosition) === 1) ? numbersNoFormat(updatedValue) + "%" : this.state.ltv,
      cltv:
        parseInt(this.state.lienPosition) < 2 && updatedField === 'ltv'
          ? numbersNoFormat(updatedValue) + "%"
          : numbersNoFormat(updatedField) === 'cltv'
          ? numbersNoFormat(updatedValue) + "%"
          : numbersNoFormat(this.state.cltv) + "%",
    });
  };

  updatePurchasePrice = (updatedField, updatedValue) => {
    const { firstLoan } = this.state;

    let loanAmt = firstLoan ? numbersNoFormat(firstLoan) : Number.MAX_SAFE_INTEGER;

    // also make sure ltv is set to a value
    if (updatedField === 'ltv' && updatedValue < 0) return;

    // if lien position is 2nd only, also return
    if (parseInt(this.state.lienPosition) === 2) return;

    // if ltv or cltv exceeds 100%, leave blank
    if (parseFloat(updatedValue) > 100) {
      this.setState({ purchasePrice: '' });
      return;
    }

    // set property value based on ltv
    const vPurchasePrice = loanAmt / (parseFloat(updatedValue) / 100);
    this.setState({
      purchasePrice: parseFloat(updatedValue) ? formatCurrency(vPurchasePrice) : '',
      ltv: updatedValue ? numbersNoFormat(updatedValue) + "%" : "",
      cltv:
        (parseInt(this.state.lienPosition) < 2 && updatedField === 'ltv' || numbersNoFormat(updatedField) === 'cltv')
          ? (updatedValue ? numbersNoFormat(updatedValue) + "%" : "")
          : numbersNoFormat(this.state.cltv) + "%",
    });
  };
  /*
  / before a submission, check to see if all the required fields
  / are filled in and that any erroneous values are flagged with
  / appropriate error messages to let the user know what to fix.
  */
  validateFields = () => {
    // keep track of any existing issues
    let errors = {};
    // validate required fields
    this.fields.forEach(field => {
      if (
        !this.state[field] &&
        field !== 'secondLoan' &&
        (field !== 'incomeVerification' || this.state.documentType === '2')
      ) {
        errors[field] = 'Required';
      }
    });

    if (this.state["county"] === "---") { errors["county"] = 'Invalid'; }
    if (this.state["state"] === "---") { errors["state"] = 'Invalid'; }

    if(parseInt(this.state.lienPosition) === 2) { errors["lienPosition"] = 'No Programs Available'; }

    var errMsg = '';
    if (Object.values(errors).length > 0 || errMsg !== '') {
      this.setState({
        errors: errors,
        loading: false,
        noProgramReason: errMsg,
      });
      console.log(errors);
      return false;
    } else {
      this.setState({ errors: null, noProgramReason: errMsg });
      return true;
    }
  };

  getIncomeVerificationType() {
    switch(this.state.incomeVerification.toString()) {
      case "1":
        return "Written VOE";
      case "2":
        return "P&L Statement (CPA Prepared)";
      case "3":
        return "P&L Statement (Self Prepared)";
      case "4":
        return "Bank Statements";
      case "5":
        return "1099 Form";
      default:
        return "";
    }
  }

  /*
  / when the user clicks the button to "view programs" we will 
  / first validate the data and then call into the database while
  / passing in the values specified by the user. using this data
  / we will retrieve the information requested and display.
  */
  getProgramInformation = async () => {
    // if selectedProgram and displayPrograms not set here, old data is retained and displayed
    this.setState({
      loading: true,
      selectedProgram: null,
      displayPrograms: false,
    });
    // if validation fails, return.
    if (!this.validateFields()) {
      this.setState({ displayPrograms: false, programs: [] });
      return;
    }

    // get programs
    const loanProduct =
      this.state.loanProduct < 9
        ? PRICING_OPTIONS.loanProduct[this.state.loanProduct - 1].label
        : PRICING_OPTIONS.loanProductPortfolio[this.state.loanProduct - 9]
            .label;
    const res = await axios.get('/program', {
      params: {
        pTimestamp: '', // latest rates
        pProgCode: '', // specific program code
        pProgFamily: '',
        pDocumentType: this.state.documentType, // full doc, portfolio
        pLoanType: loanProduct.includes('Fixed') ? 1 : 2, // fixed, adjustable
        pLoanTerm: loanProduct.includes('Fixed')
          ? loanProduct.split(' ')[0]
          : loanProduct.split('/')[0],
        pCounty: this.state.county,
        pState: this.state.stateAbbr,

        pFirstLoanAmt: numbersNoFormat(this.state.firstLoan), // 1st loan amount
        pSecondLoanAmt: numbersNoFormat(
          this.state.lienPosition > 1 ? this.state.secondLoan : 0
        ), // 2nd loan amount
        pLoanPurpose:
          parseInt(this.state.loanPurpose) > 3 ? '3' : this.state.loanPurpose, // purchase, r/t refi, c/o refi
        pPropertyType:
          PRICING_OPTIONS.propertyType[parseInt(this.state.propertyType) - 1]
            .label, // SFR, Condo (A), Condo (D), PUD, 2-4 Unit
        pOccupancyType: this.state.occupancyType, // primary, second home, investment

        pLtv: this.state.ltv,
        pCltv: this.state.cltv,
        pFico: this.state.foreignNational === '1' ? 0 : this.state.creditScore,
        pDti: this.state.dti,

        pLockPeriod: this.state.lockPeriod, // 15 Day, 30 Day
        pPmi: this.state.pmi, // No PMI, Borrower Paid, Lender Paid
        pIncomeVerification: this.getIncomeVerificationType(),
        pEscrow: this.state.impound || '1', // 1 - No Impound, 2 - Tax and Insurance, 3 - Tax, 4 - Insurance
        pForeignNational: this.state.foreignNational || '2', // 1 - Yes, 2 - No
        pMonthsReserves: this.state.monthsReserve || '1', // 1 - 2 months, 2 - 6 months, 3 - 12 months
        pPrepayPenalty: this.state.prepayPenalty || '1', // 1 - no prepay, 2 - 1 year, 3 - 2 year
      },
    });
    if (!res || res.error) {
      this.setState(
        {
          loading: false,
          displayPrograms: [],
          noProgramReason: 'Server Error While Fetching Programs!',
        },
        () =>
          this.programsRef.current &&
          window.scrollTo(0, this.programsRef.current.offsetTop)
      );
    } else {
      const programs = res.data;
      var availablePrograms = [];
      var issues = [];
      for (var i = 0; i < programs.length; i++) {
        if (!programs[i].issues || programs[i].issues.length === 0)
          availablePrograms.push(programs[i]);
        else {
          for (var j = 0; j < programs[i].issues.length; j++) {
            var dupIssue = false;
            for (var k = 0; k < issues.length; k++) {
              if (
                programs[i].issues[j].split('(')[0] === issues[k].split('(')[0]
              ) {
                dupIssue = true;
                break;
              }
            }
            if (!dupIssue) issues.push(programs[i].issues[j]);
          }
        }
      }
      var issueStr = '';
      if (availablePrograms.length === 0) {
        for (var l = 0; l < issues.length; l++) {
          issueStr = issueStr + issues[l] + '\n';
        }
      }
      this.setState(
        {
          loading: false,
          displayPrograms: availablePrograms,
          noProgramReason: issueStr,
          programs: availablePrograms,
        },
        () =>
          this.programsRef.current &&
          window.scrollTo(0, this.programsRef.current.offsetTop)
      );
    }
  };

  autoFillModalContent = () => {
    return (
      <div className="row">
        <input
          id="autofillName"
          name="autoFillName"
          label="AutoFill Name"
          placeholder="Displayed as Auto-Fill Option"
          onChange={this.captureInput}
          value={this.state.autoFillName}
        />
        <br />
        <span style={{ color: 'red', textAlign: 'center' }}>
          {this.state.modalErrMsg}
        </span>
      </div>
    );
  };

  cancelDisplay = () => {
    // set back to defaults
    this.setState({
      selectedProgram: null,
      displayPrograms: false,
      autoFillDisplay: false,
      autoFillSelectedOption: 0,
      successMsg: '',
      errors: {},
      lienPosition: 1,
      loanPurpose: 1,
      propertyType: 1,
      occupancyType: 1,
      documentType: 1,
      creditScore: '',
      state: "California",
      stateAbbr: "CA",
      county: "Los Angeles",
      zipCode: '',
      purchasePrice: '',
      firstLoan: '',
      secondLoan: '',
      ltv: '',
      cltv: '',
      loanProduct: 1,
      impound: 1,
      pmi: 1,
      lockPeriod: 1,
      compPlan: 1,
      dti: '',
      foreignNational: 2, // No
      incomeVerification: 1,
      monthsReserve: 2, // 6 Months
      prepayPenalty: 1,
    });
  };

  async autoSaveConfirm() {
    // now retrieve the information for the programs available
    // given the passed in parameters from the database
    // this method calls into the code in pricingengine.cs
    if (!this.state.autoFillName || this.state.autoFillName.length < 3) {
      this.setState({
        modalErrMsg:
          'Autofill name is required and must be at least 3 characters long',
      });
    } else {
      const response = axios.get('../saveautofill', {
        params: {
          pAutoFillName: this.state.autoFillName,
          pUserID: LoginState.userId,
          pLienPosition: this.state.lienPosition,
          pLoanPurpose: this.state.loanPurpose || 1,
          pPropertyType: this.state.propertyType || 1,
          pOccupancyType: this.state.occupancyType || 1,
          pDocumentType: this.state.documentType || 1,
          pCreditScore: this.state.creditScore,
          pState: this.state.state || "California",
          pStateAbbr: this.state.stateAbbr,
          pCounty: this.state.county || "Los Angeles",
          pZipCode: this.state.zipCode || "",
          pPurchasePrice: numbersNoFormat(this.state.purchasePrice || 0),
          pFirstLoan: numbersNoFormat(this.state.firstLoan || 0),
          pSecondLoan: numbersNoFormat(this.state.secondLoan || 0),
          pLtv: this.state.ltv,
          pCltv: this.state.cltv,
          pLoanProduct: this.state.loanProduct || 1,
          pImpound: this.state.impound || 1,
          pPmi: this.state.pmi || 1,
          pLockPeriod: this.state.lockPeriod || 1,
          pCompPlan: this.state.compPlan || 1,
          pDti: this.state.dti || 0,
          pForeignNational: this.state.foreignNational || 2,
          pIncomeVerification: this.state.incomeVerification || 1,
          pMonthsReserve: this.state.monthsReserve || 2,
          pPrepayPenalty: this.state.prepayPenalty || 1,
        },
      });

      const resultMessage = (await response).data.toString();

      if (resultMessage === 'Autofill Saved') {
        this.setState({
          successMessage: resultMessage,
          errorMessage: '',
          autoFillDisplay: false,
        });
      } else {
        this.setState({
          errorMessage: resultMessage,
          successMessage: '',
          autoFillDisplay: false,
        });
      }

      // update autofill
      this.getAutoFillOptions();
    }
  }

  /*
  / when page is loaded and when options get changed, retrieve the 
  / auto-fill options that are available
  */
  getAutoFillOptions = async () => {
    // get all user data from the server and put into right format
    const response = axios.get('/getautofilloptions', {
      params: { pUserID: LoginState.userId },
    });

    // the response.data will contain the values returned
    try {
      var resultData = (await response).data;

      // reformat
      var fillList = [];
      for (var i = 0; i < resultData.length; i++) {
        fillList.push({ label: resultData[i], value: i });
      }
      this.setState({ autoFillList: fillList });
    } catch {
      this.setState({
        autoFillList: [
          { label: 'Default (Empty)', value: 0 },
          { label: 'Standard Filled Out', value: 1 },
        ],
      });
    }
  };

  /*
  / when the user changes the index of the selected item
  / in the autofill select, this function will be called 
  / to find the settings the user specified and populate
  / the form with these values.
  */
  autoFill = e => {
    // selected option
    var opt = e.target.value;

    // clear fields to default empty values
    if (Number(opt) === 0) {
      this.setState(
        {
          autoFillSelectedOption: 0,
          errors: null,
          displayPrograms: false,
          lienPosition: 1,
          loanPurpose: null,
          propertyType: null,
          occupancyType: null,
          documentType: 1,
          creditScore: '',
          state: "California",
          stateAbbr: "CA",
          county: "Los Angeles",
          zipCode: "",
          purchasePrice: '',
          firstLoan: '',
          secondLoan: '',
          ltv: '',
          cltv: '',
          loanProduct: 1,
          impound: 1,
          pmi: '',
          lockPeriod: null,
          compPlan: null,
          dti: '',
          foreignNational: 2, // No
          incomeVerification: 1,
          monthsReserve: 2, // 6 Months
          prepayPenalty: 1, // no prepay penalty
        },
        () => window.scrollTo(0, document.body.scrollHeight)
      );
    }
    // default to commonly used values for easy submission
    else if (Number(opt) === 1) {
      this.setState(
        {
          autoFillSelectedOption: 1,
          errors: null,
          displayPrograms: false,
          lienPosition: 1,
          loanPurpose: 1,
          propertyType: 1,
          occupancyType: 1,
          documentType: 1,
          creditScore: 700,
          state: "California",
          stateAbbr: "CA",
          county: "Los Angeles",
          zipCode: "",
          purchasePrice: formatCurrency(500000),
          firstLoan: formatCurrency(400000),
          secondLoan: formatCurrency(0),
          ltv: 80,
          cltv: 80,
          loanProduct: 1,
          impound: 1,
          pmi: 2,
          lockPeriod: 2,
          compPlan: 1,
          dti: 35,
          foreignNational: 2, // No
          incomeVerification: 1,
          monthsReserve: 2, // 6 Months
          prepayPenalty: 1, // no prepay penalty
        },
        () => window.scrollTo(0, document.body.scrollHeight)
      );
    } else {
      // will call into database to retrieve desired fill
      this.getAutoFillData(opt);
    }
  };

  getAutoFillData = async opt => {
    // skip first 2 default entries
    var autoFillName = '';
    for (var i = 2; i < this.state.autoFillList.length; i++) {
      if (Number(this.state.autoFillList[i].value) === Number(opt)) {
        autoFillName = this.state.autoFillList[i].label;
        break;
      }
    }

    const response = await axios.get('/getautofilldata', {
      params: {
        pUserID: LoginState.userId,
        pAutoFillName: autoFillName,
      },
    });
    if (!response.data) return;

    const {
      lienPosition,
      loanPurpose,
      propertyType,
      occupancyType,
      documentType,
      creditScore,
      state,
      stateAbbr,
      county,
      zipCode,
      purchasePrice,
      firstLoan,
      secondLoan,
      ltv,
      cltv,
      loanProduct,
      impound,
      pmi,
      lockPeriod,
      compPlan,
      dti,
      foreignNational,
      incomeVerification,
      monthsReserve,
      prepayPenalty,
    } = response.data;

    this.setState(
      {
        autoFillSelectedOption: Number(opt),
        errors: null,
        displayPrograms: false,
        lienPosition,
        loanPurpose,
        propertyType,
        occupancyType,
        documentType,
        creditScore,
        state,
        stateAbbr,
        county,
        zipCode,
        purchasePrice: formatCurrency(purchasePrice),
        firstLoan: formatCurrency(firstLoan),
        secondLoan: formatCurrency(secondLoan),
        ltv,
        cltv,
        loanProduct,
        impound,
        pmi,
        lockPeriod,
        compPlan,
        dti,
        foreignNational,
        incomeVerification,
        monthsReserve,
        prepayPenalty,
      },
      () => window.scrollTo(0, document.body.scrollHeight)
    );
  };

  renderContent() {
    const {
      errors,
      loading,
      displayPrograms,
      selectedProgram,
      programs,
      autoFillList,
      rateAdjustments,
      priceAdjustments,
      noProgramReason,
      questionable,
      documentType,
      foreignNational,
      firstLoan,
    } = this.state;
    const isRefinance = this.state.loanPurpose && this.state.loanPurpose > 1;
    const loanProductOptions =
      documentType && documentType.toString() === '1'
        ? PRICING_OPTIONS.loanProduct
        : PRICING_OPTIONS.loanProductPortfolio;
    const lockPeriodOptions =
      documentType && documentType.toString() === '1'
        ? PRICING_OPTIONS.lockPeriod
        : PRICING_OPTIONS.lockPeriod.slice(1);
    const monthOfReserveOptions =
      firstLoan && firstLoan > 1000000
        ? PRICING_OPTIONS.monthsReserve.slice(2)
        : PRICING_OPTIONS.monthsReserve;

    return (
      <div>
        {loading && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img
              src="/images/spinner.gif"
              alt=""
              height="64px"
              width="64px"
            />
          </div>
        )}

        {(!loading && !displayPrograms) && (
        <div className="row">
          <div className="col-12 col-md-4">
            <select
              className="form-select"
              id="autoFillSelect"
              name="autoFillSelect"
              // label="Autofill Options"
              value={this.state.autoFillSelectedOption || 0}
              onChange={this.autoFill}
            >
              {autoFillList.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-8 mt-2 mt-md-0">
            <div>
              <button
                className="btn btn-primary"
                id="saveAutofillBtn"
                onClick={() => this.setState({ autoFillDisplay: true })}
              >
                Save Autofill Profile
              </button>
              <button
                className="btn btn-light ms-2"
                onClick={() => this.cancelDisplay()}
              >
                Clear Form
              </button>
            </div>
          </div>

          <hr style={{ marginTop: '35px' }} />
          <div className="col-12 col-md-4">
            <h5
              className="border-bottom-2 border-gray text-capitalize my-4"
              style={{ color: 'hsl(358,79%,47%)' }}
            >
              Loan Details
            </h5>
            <div className="row p-0">
              <div className="col-12 col-lg-6">
                <div className="mb-2">
                  {this.state.lienPosition === 2 ? (
                    <Placeholder />
                  ) : (
                    <CurrencyInput
                      label="First Loan Amount"
                      error={errors?.firstLoan}
                      id="firstLoan"
                      name="firstLoan"
                      value={this.state.firstLoan}
                      onChange={this.captureInput}
                      tabIndex={1}
                    />
                  )}
                </div>
                <div className="mb-2">
                  <PercentInput
                    label="LTV"
                    id="ltv"
                    name="ltv"
                    placeholder="LTV %"
                    error={errors?.ltv}
                    type="number"
                    value={this.state.ltv}
                    onChange={this.captureInput}
                    tabIndex={3}
                  />
                  <div id="ltvHelp" className="form-text invalid-feedback">
                    {errors?.ltv}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="lienPosition" className="form-label">
                    Lien Position
                  </label>
                  <select
                    className="form-select"
                    id="lienPosition"
                    name="lienPosition"
                    label="Lien Position"
                    value={this.state.lienPosition || 0}
                    onChange={this.captureInput}
                    tabIndex={5}
                    error={errors?.lienPosition}
                  >
                    {PRICING_OPTIONS.lienPosition.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="lienPositionHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.lienPosition}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="dti" className="form-label">
                    Occupancy Type
                  </label>
                  <select
                    className="form-select"
                    label="Occupancy Type"
                    error={errors?.occupancyType}
                    id="occupancyType"
                    name="occupancyType"
                    value={this.state.occupancyType || 0}
                    onChange={this.captureInput}
                    tabIndex={7}
                  >
                    {PRICING_OPTIONS.occupancyType.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="occupancyTypeHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.occupancyType}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="loanProduct" className="form-label">
                    Loan Product
                  </label>
                  <select
                    className="form-select"
                    label="Loan Product"
                    error={errors?.loanProduct}
                    id="loanProduct"
                    name="loanProduct"
                    value={this.state.loanProduct || 0}
                    onChange={this.captureInput}
                    tabIndex={9}
                  >
                    {loanProductOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="loanProductHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.loanProduct}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="impuond" className="form-label">
                    Impound
                  </label>
                  <select
                    className="form-select"
                    label="Escrow Account"
                    error={errors && errors.impound}
                    id="impound"
                    name="impound"
                    value={this.state.impound || 1}
                    onChange={this.captureInput}
                    tabIndex={10}
                  >
                    {PRICING_OPTIONS.impound.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div id="impoundHelp" className="form-text invalid-feedback">
                    {errors?.impound}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="compPlan" className="form-label">
                    Comp. Plan
                  </label>
                  <select
                    className="form-select"
                    label="Comp. Plan"
                    error={errors && errors.compPlan}
                    id="compPlan"
                    name="compPlan"
                    value={this.state.compPlan || 0}
                    onChange={this.captureInput}
                    tabIndex={12}
                  >
                    {PRICING_OPTIONS.compPlan.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div id="compPlanHelp" className="form-text invalid-feedback">
                    {errors?.compPlan}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                {this.state.lienPosition > 1 ? (
                  <CurrencyInput
                    error={errors && errors.secondLoan}
                    id="secondLoan"
                    label="Second Loan Amount"
                    name="secondLoan"
                    value={this.state.secondLoan}
                    onChange={this.captureInput}
                    tabIndex={2}
                  />
                ) : (
                  <Placeholder />
                )}
                <div className="mb-2">
                  <PercentInput
                    label="CLTV"
                    placeholder="CLTV %"
                    error={errors?.cltv}
                    id="cltv"
                    name="cltv"
                    type="number"
                    value={this.state.cltv}
                    onChange={this.captureInput}
                    tabIndex={4}
                  />
                  <div id="cltvHelp" className="form-text invalid-feedback">
                    {errors?.cltv}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="loanPurpose" className="form-label">
                    Loan Purpose
                  </label>
                  <select
                    className="form-select"
                    label="Loan Purpose"
                    error={errors && errors.loanPurpose}
                    id="loanPurpose"
                    name="loanPurpose"
                    value={this.state.loanPurpose || 0}
                    onChange={this.captureInput}
                    tabIndex={6}
                  >
                    {PRICING_OPTIONS.loanPurpose.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="loanPurposeHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.loanPurpose}
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="creditScore" className="form-label">
                    Documentation Type
                  </label>
                  <select
                    className="form-select"
                    label="Document Type"
                    error={errors && errors.documentType}
                    id="documentType"
                    name="documentType"
                    value={documentType || 0}
                    onChange={this.captureInput}
                    tabIndex={8}
                  >
                    {PRICING_OPTIONS.documentType.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="documentTypeHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.documentType}
                  </div>
                </div>
                <Placeholder />
                {/* PMI Only for Full Doc */}
                {documentType && documentType.toString() === '1' ? (
                  <div className="mb-2">
                    <label htmlFor="pmi" className="form-label">
                      PMI
                    </label>
                    <select
                      className="form-select"
                      label="PMI"
                      error={errors && errors.pmi}
                      id="pmi"
                      name="pmi"
                      value={this.state.pmi || 0}
                      onChange={this.captureInput}
                      tabIndex={11}
                    >
                      {PRICING_OPTIONS.pmi.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div id="pmiHelp" className="form-text invalid-feedback">
                      {errors?.pmi}
                    </div>
                  </div>
                ) : (
                  <Placeholder />
                )}
                <div className="mb-2">
                  <label htmlFor="lockPeriod" className="form-label">
                    Lock Period
                  </label>
                  <select
                    className="form-select"
                    label="Lock Period"
                    error={errors && errors.lockPeriod}
                    id="lockPeriod"
                    name="lockPeriod"
                    value={this.state.lockPeriod || 0}
                    onChange={this.captureInput}
                    tabIndex={13}
                  >
                    {lockPeriodOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="lockPeriodHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.lockPeriod}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h5
              className="border-bottom-2 border-gray text-capitalize my-4"
              style={{ color: 'hsl(358,79%,47%)' }}
            >
              Property Details
            </h5>
            <div className="row p-0">
              <div className="col-12 col-lg-6">
                <div className="mb-2">
                  <CurrencyInput
                    error={errors?.purchasePrice}
                    name="purchasePrice"
                    label={isRefinance ? 'Property Value' : 'Purchase Price'}
                    value={this.state.purchasePrice}
                    onChange={this.captureInput}
                    tabIndex={15}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="propertyType" className="form-label">
                    Property Type
                  </label>
                  <select
                    className="form-select"
                    error={errors?.propertyType}
                    label="Property Type"
                    id="propertyType"
                    name="propertyType"
                    value={this.state.propertyType || 0}
                    onChange={this.captureInput}
                    tabIndex={16}
                  >
                    {PRICING_OPTIONS.propertyType.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="propertyTypeHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.propertyType}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-2">
                  <InputWithError
                    id="zipCode"
                    name="zipCode"
                    label="Zip Code"
                    value={this.state.zipCode}
                    onChange={this.captureInput}
                    tabIndex={17}
                  />
                </div>
                <div className="mb-2">
                  <InputWithError
                    error={errors?.county}
                    label="County"
                    id="county"
                    name="county"
                    disabled={true}
                    value={this.state.county}
                  />
                </div>
                <div className="mb-2">
                  <InputWithError
                    error={errors?.state}
                    label="State"
                    id="state"
                    name="state"
                    disabled={true}
                    value={this.state.state}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h5
              className="border-bottom-2 border-gray text-capitalize my-4"
              style={{ color: 'hsl(358,79%,47%)' }}
            >
              Borrower Details
            </h5>
            {documentType && documentType.toString() === '2' && (
              <div className="row p-0">
                <div className="col-12 col-md-6 mb-2">
                  <label htmlFor="foreignNational" className="form-label">
                    Foreign National
                  </label>
                  <select
                    className="form-select"
                    label="Foreign National"
                    error={errors && errors.foreignNational}
                    id="foreignNational"
                    name="foreignNational"
                    value={foreignNational || 0}
                    onChange={this.captureInput}
                    tabIndex={20}
                  >
                    {PRICING_OPTIONS.foreignNational.map(
                      (option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      )
                    )}
                  </select>
                  <div
                    id="foreignNationalHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.foreignNational}
                  </div>
                </div>
              </div>
            )}
            <div className="row p-0">
              {/* FICO Not Applicable for Foreign Nationals */}
              {(documentType && documentType.toString() === '1') ||
              (foreignNational && foreignNational.toString() === '2') ? (
                <div className="col-12 col-lg-6 mb-2">
                  <InputWithError
                    label="Credit Score"
                    placeholder="FICO"
                    error={errors?.creditScore}
                    id="creditScore"
                    name="creditScore"
                    type="number"
                    value={this.state.creditScore}
                    onChange={this.captureInput}
                    tabIndex={21}
                  />
                  <div
                    id="creditScoreHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.creditScore}
                  </div>
                </div>
              ) : (
                <div className="col-12 col-lg-6 mb-2">
                  <InputWithError
                    label="FICO"
                    id="fico"
                    placeholder="FICO"
                    value="Not Applicable"
                    disabled={true}
                  />
                  <div id="helpFico" className="form-text invalid-feedback">
                    {errors?.fico}
                  </div>
                </div>
              )}
              <div className="col-12 col-lg-6 mb-2">
                <InputWithError
                  label="DTI"
                  placeholder="DTI"
                  error={errors?.dti}
                  id="dti"
                  name="dti"
                  value={this.state.dti}
                  onChange={this.captureInput}
                  tabIndex={22}
                />
                <div id="helpDti" className="form-text invalid-feedback">
                  {errors?.dti}
                </div>
              </div>
            </div>
            {documentType && documentType.toString() === '2' && (
              <div className="row p-0">
                <div className="col-6 mb-2">
                  <label
                    htmlFor="incomeVerification"
                    className="form-label"
                  >
                    Income Verification
                  </label>
                  <select
                    className="form-select"
                    label="Income Verification"
                    error={errors?.incomeVerification}
                    id="incomeVerification"
                    name="incomeVerification"
                    value={this.state.incomeVerification || 1}
                    onChange={this.captureInput}
                    tabIndex={23}
                  >
                    {PRICING_OPTIONS.incomeVerification.map(
                      (option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      )
                    )}
                  </select>
                  <div
                    id="incomeVerificationHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.incomeVerification}
                  </div>
                </div>
                <div className="col-6 mb-2">
                  <label htmlFor="monthsReserve" className="form-label">
                    Months of Reserve
                  </label>
                  <select
                    className="form-select"
                    label="Months of Reserve"
                    error={errors?.monthsReserve}
                    id="monthsReserve"
                    name="monthsReserve"
                    value={this.state.monthsReserve || 0}
                    onChange={this.captureInput}
                    tabIndex={24}
                  >
                    {monthOfReserveOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    id="monthsReserveHelp"
                    className="form-text invalid-feedback"
                  >
                    {errors?.monthsReserve}
                  </div>
                </div>
              </div>
            )}

            {/* Investment and Portfolio */}
            {documentType &&
              documentType.toString() === '2' &&
              this.state.occupancyType === '3' && (
                <div className="row p-0">
                  <div className="col-6 mb-2">
                    <label htmlFor="prepayPenalty" className="form-label">
                      Prepay Penalty (MTGP2 Only)
                    </label>
                    <select
                      className="form-select"
                      label="Prepay Penalty"
                      error={errors?.prepayPenalty}
                      id="prepayPenalty"
                      name="prepayPenalty"
                      value={this.state.prepayPenalty || 1}
                      onChange={this.captureInput}
                      tabIndex={25}
                    >
                      {PRICING_OPTIONS.prepayPenalty.map(
                        (option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        )
                      )}
                    </select>
                    <div
                      id="prepayPenaltyHelp"
                      className="form-text invalid-feedback"
                    >
                      {errors?.prepayPenalty}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div className="col-12 mt-4">
            <button
              className="btn btn-primary"
              disabled={loading || this.state.rateSuspend || parseInt(this.state.lienPosition) === 2}
              onClick={() => this.CheckRateSuspended(true)}
            >
              {loading ? 'Retrieving' : 'View Programs'}
            </button>
            {parseInt(this.state.lienPosition) === 2 && (
              <span style={{color:'red', fontSize:'11', paddingLeft:'25px'}}>
                (No Programs Currently Available For 2nd Liens)
              </span>
            )}
          </div>
        </div>
        )}

        {(!loading && displayPrograms) && (
        <div className="row">
          <div className="col-12">
            {this.state.rateSuspend && (
              <div className="row mt-4" style={{ marginTop:'20px', textAlign:'center', color:'red' }}>
                <div className="text-center">
                  <hr />
                </div>
                <table style={{ marginTop:'15px', width:'420px', margin: '0 auto' }}>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="/images/Lock.png"
                          height="100px"
                          alt="Rates Suspended"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight:'700', fontFamily:'Montserrat', color:'red', fontSize:'20px', textAlign:'center' }}>
                        Rates Currently Suspended<br/>
                        (New Ratesheet Is Being Prepared)
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center">
                  <hr />
                </div>
              </div>
            )}
          </div>

          <div className="row mb-2">
            <div className="col-2 col-md-2 mb-2">
              <small className="text-muted">First Loan Amount</small>
              <p className="fw-bold mb-1">{this.state.firstLoan}</p>
              <small className="text-muted">{isRefinance ? 'Property Value' : 'Purchase Price'}</small>
              <p className="fw-bold mb-1">{this.state.purchasePrice}</p>
              <small className="text-muted">LTV/CLTV</small>
              <p className="fw-bold mb-1">{this.state.ltv}% / {this.state.cltv}%</p>
            </div>
            <div className="col-2 col-md-2 mb-2">
              <small className="text-muted">Documentation Type</small>
              <p className="fw-bold mb-1">{PRICING_OPTIONS.documentType[parseInt(this.state.documentType)-1].label}</p>
              <small className="text-muted">Loan Purpose</small>
              <p className="fw-bold mb-1">{PRICING_OPTIONS.loanPurpose[parseInt(this.state.loanPurpose)-1].label}</p>
              <small className="text-muted">Property Type</small>
              <p className="fw-bold mb-1">{PRICING_OPTIONS.propertyType[parseInt(this.state.propertyType)-1].label}</p>
              <small className="text-muted">Occupancy Type</small>
              <p className="fw-bold mb-1">{PRICING_OPTIONS.occupancyType[parseInt(this.state.occupancyType)-1].label}</p>
            </div>
            <div className="col-2 col-md-2 mb-2">
              <small className="text-muted">Loan Product</small>
              <p className="fw-bold mb-1">
                {this.state.loanProduct < 9
                  ? PRICING_OPTIONS.loanProduct[parseInt(this.state.loanProduct)-1].label
                  : PRICING_OPTIONS.loanProductPortfolio[parseInt(this.state.loanProduct)-9].label
                }
              </p>
              <small className="text-muted">Credit Score</small>
              <p className="fw-bold mb-1">{this.state.foreignNational.toString() === "1" ? "---" : this.state.creditScore}</p>
              <small className="text-muted">DTI</small>
              <p className="fw-bold mb-1">{this.state.dti}%</p>
            </div>
            <div className="col-2 col-md-2 mb-2">
              <small className="text-muted">Impound</small>
              <p className="fw-bold mb-1">{PRICING_OPTIONS.impound[parseInt(this.state.impound)-1].label}</p>
              <small className="text-muted">PMI</small>
              <p className="fw-bold mb-1">{PRICING_OPTIONS.pmi[parseInt(this.state.pmi)-1].label}</p>
              <small className="text-muted">Lock Period</small>
              <p className="fw-bold mb-1">{this.state.documentType.toString() === "2" ? 
                '30 Days' :
                PRICING_OPTIONS.lockPeriod[parseInt(this.state.lockPeriod)-1].label}
              </p>
            </div>
            <div className="col-2 col-md-2 mb-2">
              <small className="text-muted">County</small>
              <p className="fw-bold mb-1">{this.state.county}</p>
              <small className="text-muted">State</small>
              <p className="fw-bold mb-1">{this.state.state}</p>
            </div>
            {this.state.documentType.toString() === "2" && (
              <div className="col-2 col-md-2 mb-2">
                <small className="text-muted">Foreign National</small>
                <p className="fw-bold mb-1">{PRICING_OPTIONS.foreignNational[parseInt(this.state.foreignNational)-1].label}</p>
                <small className="text-muted">Income Verification</small>
                <p className="fw-bold mb-1">{PRICING_OPTIONS.incomeVerification[parseInt(this.state.incomeVerification)-1].label}</p>
                <small className="text-muted">Months of Reserve</small>
                <p className="fw-bold mb-1">{PRICING_OPTIONS.monthsReserve[parseInt(this.state.monthsReserve)-1].label}</p>
              </div>
            )}
          </div>
          
          <div>
            <button
              className="btn btn-primary"
              id="backBtn"
              onClick={() => this.setState({ displayPrograms: false })}
            >
              Pricing Options
            </button>
          </div>
          
          {noProgramReason && (
            <div className="row">
              <div className="text-center">
                <hr />
                <ProgramList
                  availablePrograms={programs}
                  progErr={noProgramReason}
                />
              </div>
            </div>
          )}
          {displayPrograms && (
            <React.Fragment>
              {displayPrograms.length > 0 && (    
                <div className="row">
                  <hr style={{ marginTop: '35px' }} />
                  <div className="col-12 col-lg-5" ref={this.programsRef}>
                    <h5
                      className="border-bottom-2 border-gray text-capitalize my-4"
                      style={{ color: 'hsl(358,79%,47%)' }}
                    >
                      Available Programs
                    </h5>
                    <ProgramList
                      availablePrograms={programs}
                      rateAdjustments={rateAdjustments}
                      priceAdjustments={priceAdjustments}
                      questionable={questionable}
                      onSelect={program =>
                        this.setState({ selectedProgram: program }, () =>
                          window.scrollTo(0, document.body.scrollHeight)
                        )
                      }
                      selectedProgram={selectedProgram}
                    />
                    <h5
                      className="border-bottom-2 border-gray text-capitalize my-4"
                      style={{ color: 'hsl(358,79%,47%)' }}
                    >
                      Adjustments
                    </h5>
                    {selectedProgram ? (
                      <Adjustments
                        rateAdjustments={selectedProgram.rateAdjustments}
                        priceAdjustments={selectedProgram.priceAdjustments}
                      />
                    ) : (
                      <p style={{ marginLeft: '10px' }}>
                        Select a Program to View Adjustments
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-lg-7">
                    <h5
                      className="border-bottom-2 border-gray my-4"
                      style={{ color: 'hsl(358,79%,47%)' }}
                    >
                      Pricing 
                      {selectedProgram && (
                        <span style={{fontSize:'14px', color: 'red', marginLeft:'10px'}}>{'(Rate Premium Cap: ' + selectedProgram.rateCap + ')'}</span>
                      )}
                    </h5>
                    {selectedProgram ? (
                      <ProgramRates
                        programCode={selectedProgram.code}
                        programTerm={selectedProgram.term}
                        rateCap={selectedProgram.rateCap}
                        programRates={selectedProgram.rateList}
                        rateAdjustments={selectedProgram.rateAdjustments}
                        priceAdjustments={selectedProgram.priceAdjustments}
                        firstLoan={numbersNoFormat(this.state.firstLoan)}
                        loanProduct={this.state.loanProduct}
                      />
                    ) : (
                      <p style={{ marginLeft: '10px' }}>
                        Select a Program to View Pricing
                      </p>
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        )}
      </div>
    );
  }

  /*
  / this function will render the primary display for the pricing
  / engine page. 
  */
  render() {
    const { autoFillDisplay, errorMessage, successMessage } = this.state;

    return (
      <div className="container-xxl">
        <div className="row">
          <div className="col-12 mb-2">
            <h1 className="h4 text-uppercase">pricing</h1>
          </div>
        </div>
        <div className="col-12">{this.renderContent()}</div>
        {this.state.successMessage && (
          <div
            className="toast align-items-center"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">{this.state.successMessage}</div>
              <button
                type="button"
                className="btn-close me-2 m-auto"
                onClick={() => this.setState({ successMessage: null })}
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        )}
        {/* AUTOFILL MODAL */}
        <Modal
          height="200px"
          width="400px"
          title="Save AutoFill Data?"
          show={autoFillDisplay}
          onAccept={() => this.autoSaveConfirm()}
          onDecline={() =>
            this.setState({ autoFillDisplay: false, modalErrMsg: '' })
          }
          buttonMessage="Save"
        >
          {this.autoFillModalContent()}
        </Modal>
        {errorMessage && (
          <Toast
            type="danger"
            title={errorMessage}
            onClose={() => this.setState({ errorMessage: null })}
          />
        )}
        {successMessage && (
          <Toast
            width="200px"
            type="success"
            title={successMessage}
            onClose={() => this.setState({ successMessage: null })}
          />
        )}
      </div>
    );
  }
}

export default Pricing;
