import React, { Component } from 'react';
import axios from 'axios';
import Toast from '../components/Toast';
import { STATUS_COLORS, PRICING_OPTIONS } from '../helpers/constants';
import { getFormattedName, getPropertyType } from '../helpers/utils';
import CanvasJSReact from '../libraries/canvasjs.react';
import LoginState from './LoginState';

class LoanSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loan: {},
      searchLog: [],
      graph: false,
    };
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ loan: {} });
    this.GetSearchLog(fileID);
    this.GetSummaryData(fileID);
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id);
  }

  // get data before page loads
  GetSummaryData = async fileID => {
    axios
      .get('/getloansummary', {
        params: {
          pFileID: fileID,
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({ loan: res.data });
        }
      })
      .catch(e => console.log(e, 'Error fetching loan details'));
  };

  GetPropertyType() {
    var unitCount = parseInt(this.state.loan.numberOfUnits);
    if (unitCount > 1) {
      return unitCount + ' Unit';
    }
    return getPropertyType(this.state.loan.propertyType);
  }

  GetOccupancyType() {
    try {
      return PRICING_OPTIONS.occupancyType[
        parseInt(this.state.loan.occupancyType) - 1
      ].label;
    } catch {
      return '---';
    }
  }

  render() {
    const { loan, graph } = this.state;
    const defaultAddress = `${loan.street}\n${loan.city}, ${loan.state} ${loan.zip}`;
    const formattedBorrower = getFormattedName(loan.borrower);

    const CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const graphOptions = {
      // exportEnabled: true,
      // axisY: {
      //   includeZero: false,
      //   interval: 2,
      //   valueFormatString: "#0.## °C"
      // },
      // axisX: {
      //   interval: 1,
      //   valueFormatString: "DD-MMM"
      // },
      // toolTip: {
      //   content: "{x} </br> Min: {y[0]}, Max: {y[1]}"
      // },
      // data: [
      // {
      //   type: "rangeBar",
      //   yValueFormatString: "#0.## °C",
      //   indexLabel: "{y[#index]}",
      //   indexLabelFontSize: 14,
      //   dataPoints: [
      //     {x: new Date(2014,3,1), y: [ 17,33]},
      //     {x: new Date(2014,3,2), y: [ 18,35]},
      //     {x: new Date(2014,3,3), y: [ 18,32]},
      //     {x: new Date(2014,3,4), y: [ 18,32]},
      //     {x: new Date(2014,3,5), y: [ 20,35]},
      //     {x: new Date(2014,3,6), y: [ 20,38]},
      //     {x: new Date(2014,3,7), y: [ 21,32]}
      //   ]
      // }]
      animationEnabled: true,
      axisY: {
        title: 'Date',
      },
      axisX: {
        title: 'Step',
      },
      data: [
        {
          type: 'rangeBar',
          dataPoint: [
            {
              label: 'Registered',
              x: 1,
              y: [
                new Date(Date.UTC(2022, 2, 1)),
                new Date(Date.UTC(2022, 3, 1)),
              ],
            },
            {
              label: 'Registered',
              x: 2,
              y: [
                new Date(Date.UTC(2022, 4, 1)),
                new Date(Date.UTC(2022, 5, 1)),
              ],
            },
            {
              label: 'Processed',
              x: 3,
              y: [
                new Date(Date.UTC(2022, 6, 1)),
                new Date(Date.UTC(2022, 7, 1)),
              ],
            },
            {
              label: 'Processed',
              x: 4,
              y: [
                new Date(Date.UTC(2022, 8, 1)),
                new Date(Date.UTC(2022, 9, 1)),
              ],
            },
            // { label: "Registered", y: [loan.registeredDate, loan.inSubmissionDate] },
            // { label: "In submission", y: [loan.inSubmissionDate, loan.submittedDate] },
            // { label: "Submitted", y: [loan.submittedDate, loan.inUnderwritingDate] },
            // { label: "In underwriting", y: [loan.inUnderwritingDate, loan.approvedDate] },
            // { label: "Approved", y: [loan.approvedDate, loan.ctdDate] },
            // { label: "Clear to close", y: [loan.ctdDate, loan.docOutDate] },
            // { label: "Doc out", y: [loan.docOutDate, loan.docInDate] },
            // { label: "doc in", y: [loan.docInDate, loan.inReviewDate] },
            // { label: "In review", y: [loan.inReviewDate, loan.reviewedDate] },
            // { label: "Reviewed", y: [loan.reviewedDate, loan.ctfDate] },
            // { label: "Clear to funding", y: [loan.ctfDate, loan.fundingDate] },
            // { label: "Funded", y: [loan.fundingDate, loan.holdDate] },
            // { label: "On hold", y: [loan.holdDate, loan.declineDate] },
            // { label: "Declined", y: [loan.declineDate, loan.cancelDate] },
            // { label: "Cancelled", y: [loan.cancelDate, loan.appraisalOrderDate] },
            // { label: "Appraisal ordered", y: [loan.appraisalOrderDate, loan.lockStartDate] },
            // { label: "Date locked", y: [loan.lockStartDate, loan.lockExpirationDate] },
            // { label: "Lock expiration", y: [loan.lockExpirationDate, loan.lockDays] },
            // { label: "Lock days", y: [loan.lockDays, loan.epoExpirationDate] },
            // { label: "EPO expiration", y: [loan.epoExpirationDate,10000] }
          ],
        },
      ],
    };

    if (!this.state.loan.borrower) {
      return (
        <div className="row">
          <div style={{ width: '100%', textAlign: 'center' }}>
            <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 text-uppercase">
              loan summary
              <button
                className="btn btn-text dropdown-toggle"
                style={{ marginLeft: '5px' }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-list"></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <p className="dropdown-item mb-0 disabled">Recent Files</p>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {this.state.searchLog.length > 0 ? (
                  <>
                    {this.state.searchLog.map(file => (
                      <li key={file.encodedFileID}>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            this.props.history.push(
                              '/pipeline/loan-summary/' + file.encodedFileID
                            );
                            this.PageLoad(file.encodedFileID);
                          }}
                        >
                          {file.encodedFileID} ({file.borrower})
                        </span>
                      </li>
                    ))}
                  </>
                ) : (
                  <li>
                    <p className="dropdown-item mb-0 disabled">
                      No Recent Files
                    </p>
                  </li>
                )}
              </ul>
              {LoginState.userId === 1000009 && (
                <span
                  style={{
                    fontSize: '14px',
                    marginLeft: '20px',
                    position: 'relative',
                    top: '-3px',
                    color: 'red',
                  }}
                >
                  (ID: {loan.fileName} - Byte: {loan.fileDataID})
                </span>
              )}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4 mb-2">
            <div className="border rounded px-3 py-2 bg-white">
              <span className="me-2">Status</span>
              <p
                className={`fw-bold mb-0 text-${
                  STATUS_COLORS[
                    loan.loanStatus && loan.loanStatus.toLowerCase()
                  ]
                }`}
              >
                {loan.loanStatus}
              </p>
            </div>
          </div>
          <div className="col-6 col-md-4 mb-2">
            <div className="border rounded px-3 py-2 bg-white">
              <span className="me-2">Primary Borrower</span>
              <p className="fw-bold mb-0">{formattedBorrower}</p>
            </div>
          </div>
          <div className="col-12">
            <p className="border-bottom text-muted mb-0">TIMELINE</p>
          </div>
          {!graph ? (
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-2">
                  <small className="text-muted">Registered</small>
                  <p className="fw-bold mb-1">{loan.registeredDate}</p>
                  <small className="text-muted">In Submission</small>
                  <p className="fw-bold mb-1">{loan.inSubmissionDate}</p>
                  <small className="text-muted">Submitted</small>
                  <p className="fw-bold mb-1">{loan.submittedDate}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <small className="text-muted">In Underwriting</small>
                  <p className="fw-bold mb-1">{loan.inUnderwritingDate}</p>
                  <small className="text-muted">Approved</small>
                  <p className="fw-bold mb-1">{loan.approvedDate}</p>
                  <small className="text-muted">Clear to Close</small>
                  <p className="fw-bold mb-1">{loan.ctcDate}</p>
                  <small className="text-muted">Doc Out</small>
                  <p className="fw-bold mb-1">{loan.docOutDate}</p>
                  <small className="text-muted">Doc In</small>
                  <p className="fw-bold mb-1">{loan.docInDate}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <small className="text-muted">In Review</small>
                  <p className="fw-bold mb-1">{loan.inReviewDate}</p>
                  <small className="text-muted">Reviewed</small>
                  <p className="fw-bold mb-1">{loan.reviewedDate}</p>
                  <small className="text-muted">Clear to Funding</small>
                  <p className="fw-bold mb-1">{loan.ctfDate}</p>
                  <small className="text-muted">Funded</small>
                  <p className="fw-bold mb-1">{loan.fundingDate}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <small className="text-muted">On Hold</small>
                  <p className="fw-bold mb-1">{loan.onHoldDate}</p>
                  <small className="text-muted">Declined</small>
                  <p className="fw-bold mb-1">{loan.declinedDate}</p>
                  <small className="text-muted">Cancelled</small>
                  <p className="fw-bold mb-1">{loan.canceledDate}</p>
                </div>
                <div className="col-12 col-md-6 col-lg-2">
                  <small className="text-muted">Appraisal Ordered</small>
                  <p className="fw-bold mb-1">{loan.appraisalOrderDate}</p>
                  <small className="text-muted">Date Locked</small>
                  <p className="fw-bold mb-1">{loan.lockStartDate}</p>
                  <small className="text-muted">Lock Expiration</small>
                  <p className="fw-bold mb-1">{loan.lockExpirationDate}</p>
                  <small className="text-muted">Lock Days</small>
                  <p className="fw-bold mb-1">{loan.lockDays}</p>
                  <small className="text-muted">EPO Expiration</small>
                  <p className="fw-bold mb-1">{loan.epoExpirationDate}</p>
                </div>
              </div>
            </div>
          ) : (
            <CanvasJSChart options={graphOptions} />
          )}
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <p className="border-bottom text-muted mb-0">LOAN DETAILS</p>
            <div className="row">
              <div className="col-12 col-md-6">
                <small className="text-muted">1st Loan Amount</small>
                <p className="fw-bold mb-1">{loan.firstLoan}</p>
                <small className="text-muted">LTV</small>
                <p className="fw-bold mb-1">
                  {loan.ltvCltvHcltv ? loan.ltvCltvHcltv.split('/')[0] : '---'}
                </p>
                <small className="text-muted">Lien Position</small>
                <p className="fw-bold mb-1">1st Lien</p>
                <small className="text-muted">Occupancy Type</small>
                <p className="fw-bold mb-1">{this.GetOccupancyType()}</p>
              </div>
              <div className="col-12 col-md-6">
                <small className="text-muted">2nd Loan Amount</small>
                <p className="fw-bold mb-1">{loan.secondLoan}</p>
                <small className="text-muted">CLTV</small>
                <p className="fw-bold mb-1">
                  {loan.ltvCltvHcltv
                    ? loan.ltvCltvHcltv.split('/')[1].trim()
                    : '---'}
                </p>
                <small className="text-muted">Loan Purpose</small>
                <p className="fw-bold mb-1">{loan.loanPurpose}</p>
                <small className="text-muted">Document Type</small>
                <p className="fw-bold mb-1">
                  {loan.programCode
                    ? loan.programCode.toString().includes('MTGP')
                      ? 'Reduced Doc'
                      : 'Full Doc'
                    : ''}
                </p>
              </div>
              <div className="col-12">
                <small className="text-muted">Loan Product</small>
                <p className="fw-bold mb-1">{loan.programCode}</p>
              </div>
              <div className="col-12 col-md-6">
                <small className="text-muted">Escrow Account</small>
                <p className="fw-bold mb-1">{loan.escrow}</p>
              </div>
              <div className="col-12 col-md-6">
                <small className="text-muted">PMI</small>
                <p className="fw-bold mb-1">{loan.pmi}</p>
              </div>
              <div className="col-12 col-md-6">
                <small className="text-muted">Interest Rate</small>
                <p className="fw-bold mb-1">{loan.intRate}</p>
              </div>
              <div className="col-12 col-md-6">
                <small className="text-muted">Qualifying Rate</small>
                <p className="fw-bold mb-1">{loan.qualRate}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-4">
            <p className="border-bottom text-muted mb-0">PROPERTY DETAILS</p>
            <div className="row">
              <div className="col-12 col-md-6">
                <small className="text-muted">Appraised Value</small>
                <p className="fw-bold mb-1">{loan.propertyValue}</p>
              </div>
              <div className="col-12 col-md-6">
                {loan.loanPurpose === '---' || loan.loanPurpose === 'PUR' ? (
                  <>
                    <small className="text-muted">Purchase Price</small>
                    <p className="fw-bold mb-1">{loan.salePrice}</p>
                  </>
                ) : (
                  <>
                    <small className="text-muted">Estimated Value</small>
                    <p className="fw-bold mb-1">{loan.estimatedValue}</p>
                  </>
                )}
              </div>
              <div className="col-12">
                <small className="text-muted">Property Type</small>
                <p className="fw-bold mb-1">{this.GetPropertyType()}</p>
                <small className="text-muted">County</small>
                <p className="fw-bold mb-1">{loan.county}</p>
                <small className="text-muted">Property Address</small>
                <p className="fw-bold mb-1">{defaultAddress}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mt-4">
            <p className="border-bottom text-muted mb-0">PAYMENT DETAILS</p>
            <small className="text-muted">DTI</small>
            <p className="fw-bold mb-1">{loan.dti}</p>
            <small className="text-muted">Principal &amp; Interest</small>
            <p className="fw-bold mb-1">{loan.pi}</p>
            <small className="text-muted">Property Tax</small>
            <p className="fw-bold mb-1">{loan.propTax}</p>
            <small className="text-muted">Hazard Insurance</small>
            <p className="fw-bold mb-1">{loan.hazIns}</p>
            <small className="text-muted">Mortgage Insurance</small>
            <p className="fw-bold mb-1">{loan.mi}</p>
            <small className="text-muted">HOA</small>
            <p className="fw-bold mb-1">{loan.hoa}</p>
            <small className="text-muted">Total</small>
            <p className="fw-bold mb-1">{loan.totalPITI}</p>
          </div>
        </div>
        {this.state.successMessage && (
          <Toast
            type="success"
            title={this.state.successMessage}
            onClose={() => this.setState({ successMessage: null })}
          />
        )}
        {this.state.errorMessage && (
          <Toast
            type="error"
            title={this.state.errorMessage}
            onClose={() => this.setState({ errorMessage: null })}
          />
        )}
      </div>
    );
  }
}

export default LoanSummary;
