import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LoginState from './LoginState';

const ModalCard = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 30px;
  background-color: ${p => p.theme.colors.primary700};
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 2px;
`;

const ModalHeader = styled.div`
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.span`
  color: white;
  margin: 0;
  padding-left: 15px;
  font-size: 14px;
  height: 30px;
  position: relative;
  top: -22px;
`;

export default class GlobalMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: '',
      messageID: '',
      lastClosed: localStorage.getItem('LastMessage'),
    };
    this.getLatestMessage();
  }

  componentDidMount() {
    setInterval(() => {
      this.getLatestMessage();
    }, 60000);
  }

  closeMessage() {
    // update loginstate so we know we've already seen this message
    localStorage.setItem('LastMessage', this.state.messageID);
    this.setState({ show: false, lastClosed: this.state.messageID });
  }

  async getLatestMessage() {
    // skip if logged out
    if(!LoginState.loggedIn || !LoginState.userName)
      return;

    const response = axios.get('/getLatestMessage', {
      params: { pUserName: LoginState.userName },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    this.setState({
      message: resultData ? resultData.message : '',
      messageID: resultData ? resultData.messageID : '',
      show: resultData && this.state.lastClosed !== resultData.messageID,
    });
  }

  render() {
    return this.state.show ? (
      <ModalCard>
        <ModalHeader>
          <ModalTitle><div style={{textAlign:'center', width: '100%'}}>{this.state.message}</div></ModalTitle>
        </ModalHeader>
      </ModalCard>
    ) : (
      <></>
    );
  }
}
