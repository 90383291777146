import { Redirect, Route, Switch } from 'react-router-dom';

import EscrowConditions from '../components/EscrowConditions';
import EscrowSearch from '../components/EscrowSearch';
//change this import
import Landing from '../containers/Landing/index';
import TimedOut from '../containers/TimedOut';
import React from 'react';
import LoanWizard from '../components/LoanWizard';
import DailyCheck from '../components/DailyCheck';
import DownloadFile from '../components/DownloadFile';
import ForgotPassword from '../containers/ForgotPassword';
import GeocodeFinder from '../containers/GeocodeFinder';
import ProgramComparison from '../containers/ProgramComparison';
import PageRedirect from '../components/PageRedirect';

const PublicRoutes = () => {
  // when not logged in, this will save the last page attempted to visit into
  // into a stored location. then we can redirect there after login.
  var saveLoc = window.location.href.substring(8);
  saveLoc = saveLoc.substring(saveLoc.indexOf('/'));
  if (saveLoc.length > 1) localStorage.setItem('lastPage', saveLoc);

  return (
    <Switch>
      <Route exact path="/geoid-validator" component={GeocodeFinder} />
      <Route path="/escrow-file/:id" component={EscrowConditions} />
      <Route path="/escrow" component={EscrowSearch} />
      <Route path="/loan-wizard/:pg" component={LoanWizard} />
      <Route path="/dailycheck" component={DailyCheck} />
      <Route path="/download" component={DownloadFile} />
      <Route path="/timedout" component={TimedOut} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/compare-programs" component={ProgramComparison} />
      <Route exact path="/" component={Landing} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default PublicRoutes;
