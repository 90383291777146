import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import AppraisalFinder from '../containers/AppraisalFinder';
import AppraisalOrder from '../components/AppraisalOrder';
// components
import Circumstance from '../components/Circumstance';
import Conditions from '../components/Conditions';
// containers
import Dashboard from '../containers/Dashboard';
import Closing from '../components/Closing';
import EscrowConditions from '../components/EscrowConditions';
import EscrowSearch from '../components/EscrowSearch';
import Footer from '../components/Footer';
import Forms from '../components/Forms';
import Help from '../containers/Help';
import LenderPanel from '../containers/LenderPanel';
import ImportantDates from '../components/ImportantDates';
import LoanSummary from '../components/LoanSummary';
import NewLoan from '../components/RegisterLoan/NewLoan';
import Pipeline from '../containers/Pipeline';
import Pricing from '../containers/Pricing';
import ProductMatrix from '../components/ProductMatrix';
import RateLock from '../components/RateLock';
import React, { useContext, useRef, useEffect } from 'react';
import Submission from '../components/Submission';
import Resources from '../containers/Resources';
import LoanDocs from '../containers/LoanDocs';
import Settings from '../containers/Settings';
import Sidebar from '../components/SideBar';
import StatusBoard from '../components/StatusBoard';
import Wizard1003 from '../components/Wizard1003';
import WizardSubmission from '../components/WizardSubmission';
import Messages from '../components/Messages';
import AssociatedEmail from '../components/AssociatedEmail';
import EmailSearch from '../components/EmailSearch';
import Admin from '../components/Admin';
import AdminMessage from '../components/AdminMessage';
import DownloadFile from '../components/DownloadFile';
import UploadPdf from '../components/UploadPdf';
import GeocodeFinder from '../containers/GeocodeFinder';
import LoanContacts from '../containers/LoanContacts';
import { ACTIONS, Session } from '../helpers/sessionContext';
import FileDetails from '../components/FileDetails/FileDetails';
import DailyRates from '../components/DailyRates';
import PageRedirect from '../components/PageRedirect';
import ProgramComparison from '../containers/ProgramComparison';
import Restricted from '../containers/Restricted';
import LoginState from '../components/LoginState';
import axios from 'axios';

const AuthedRoutes = ({ user }) => {
  const { dispatch } = useContext(Session);
  const history = useHistory();
  const location = useLocation();

  const Ref = useRef(null);
  const startTimer = e => {
    const latestStored = localStorage.getItem('lastTimeStamp');
    if (
      Date.parse(e === latestStored ? e : latestStored) -
        Date.parse(new Date()) <=
      0
    ) {
      // timed out - logout
      dispatch({ type: ACTIONS.REMOVE_USER });
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('lastTimeStamp');
      history.push('/timedout');
    }
  };
  const clearTimer = e => {
    if (Ref.current) clearInterval(Ref.current);
    localStorage.setItem('lastTimeStamp', e);
    const id = setInterval(() => {
      startTimer(e);
    }, 10000);
    Ref.current = id;
  };
  const getTimeoutSeconds = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60 * 60 * 6); // 6 Hours
    return deadline;
  };
  useEffect(() => {
    clearTimer(getTimeoutSeconds());
  }, []);

  const checkFileAccess = async e => {
    if(LoginState.userOrg === '1000001' || LoginState.licensed || LoginState.userId === 1000009) { return; }
    if(e.includes("pipeline") && !e.includes("all")) {
      const response = axios.get('/checkAccess', {
        params: {
          userName: LoginState.userName,
          orgID: LoginState.userOrg,
          pFileID: e.split('/')[e.split('/').length-1],
        },
      });
      var resultData = (await response).data;
      if(resultData !== "Access") { history.push('/restricted'); }
    }
  };

  checkFileAccess(location.pathname);
  if(LoginState.licensed || LoginState.userId === 1000009) {
    return (
      <div className="main" onClick={() => clearTimer(getTimeoutSeconds())}>
        <div className="bg border-end position-fixed d-flex flex-column p-3 sidebar overflow-auto">
          <Sidebar />
        </div>
        <Switch>
          {/* NEW LOAN */}
          <Route path="/new-loan" component={NewLoan} />

          {/* PIPELINE */}
          <Route exact path="/pipeline/:sub" component={Pipeline} />
          <Route
            path="/pipeline/important-dates/:id"
            component={ImportantDates}
          />
          <Route path="/pipeline/loan-summary/:id" component={LoanSummary} />
          <Route path="/pipeline/contacts/:id" component={LoanContacts} />
          <Route path="/pipeline/submission/:id" component={Submission} />
          <Route path="/pipeline/conditions/:id" component={Conditions} />
          <Route path="/pipeline/documents/:id" component={LoanDocs} />
          <Route path="/pipeline/appraisals/:id" component={AppraisalOrder} />
          <Route path="/pipeline/closing/:id" component={Closing} />
          <Route path="/pipeline/closing/:id" component={Closing} />
          {!LoginState.limited && (
            <Route path="/pipeline/lock/:id" component={RateLock} />
          )}
          <Route
            path="/pipeline/change-in-circumstance/:id"
            component={Circumstance}
          />
          {/* keeping this in case old email links break */}
          <Route
            path="/pipeline/change-of-circumstance/:id"
            component={Circumstance}
          />

          {/* ESCROW */}
          <Route path="/escrow" component={EscrowSearch} />
          <Route path="/escrow-file/:id" component={EscrowConditions} />

          {/* RESOURCES */}
          <Redirect exact from="/tools" to="/pricing" />
          {!LoginState.limited && (
            <Route path="/tools/pricing" component={Pricing} />
          )}
          <Route path="/tools/appraisal-finder" component={AppraisalFinder} />
          <Route exact path="/resources" component={Resources} />
          <Route
            exact
            path="/resources/product-matrix"
            component={ProductMatrix}
          />
          <Route exact path="/resources/forms" component={Forms} />
          {!LoginState.limited && (
            <Route exact path="/resources/daily-rates" component={DailyRates} />
          )}
    
          <Route
            exact
            path="/resources/geoid-validator"
            component={GeocodeFinder}
          />

          {/* OTHERS */}
          <Route path="/associated-email/:id" component={AssociatedEmail} />
          <Route exact path="/lenderpanel/:tab" component={LenderPanel} />
          <Route exact path="/status-board/:tab" component={StatusBoard} />
          <Route exact path="/email-search" component={EmailSearch} />
          <Route exact path="/wizard1003" component={Wizard1003} />
          <Route exact path="/details/:id" component={FileDetails} />
          <Route exact path="/messages" component={Messages} />

          <Route
            exact
            path="/wizardsubmission/:id"
            component={WizardSubmission}
          />
          <Route exact path="/settings/:tab" component={Settings} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/adminmessage" component={AdminMessage} />
          <Route path="/pdf" component={UploadPdf} />
          <Route path="/download" component={DownloadFile} />
          <Route exact path="/redirect/:id" component={PageRedirect} />
          <Route exact path="/compare-programs" component={ProgramComparison} />
          <Route path="/restricted" component={Restricted} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
        {!location.pathname.includes('details') && <Footer />}
      </div>
    );
  }

  return (
    <div className="main" onClick={() => clearTimer(getTimeoutSeconds())}>
      <div className="bg border-end position-fixed d-flex flex-column p-3 sidebar overflow-auto">
        <Sidebar />
      </div>
      <Switch>
        {/* NEW LOAN */}
        <Route path="/new-loan" component={NewLoan} />

        {/* PIPELINE */}
        <Route exact path="/pipeline/:sub" component={Pipeline} />
        <Route
          path="/pipeline/important-dates/:id"
          component={ImportantDates}
        />
        <Route path="/pipeline/loan-summary/:id" component={LoanSummary} />
        <Route path="/pipeline/contacts/:id" component={LoanContacts} />
        <Route path="/pipeline/submission/:id" component={Submission} />
        <Route path="/pipeline/conditions/:id" component={Conditions} />
        <Route path="/pipeline/documents/:id" component={LoanDocs} />
        <Route path="/pipeline/appraisals/:id" component={AppraisalOrder} />
        <Route path="/pipeline/closing/:id" component={Closing} />
        <Route path="/pipeline/closing/:id" component={Closing} />
        {!LoginState.limited && (
          <Route path="/pipeline/lock/:id" component={RateLock} />
        )}
        <Route
          path="/pipeline/change-in-circumstance/:id"
          component={Circumstance}
        />
        {/* keeping this in case old email links break */}
        <Route
          path="/pipeline/change-of-circumstance/:id"
          component={Circumstance}
        />

        {/* ESCROW */}
        <Route path="/escrow" component={EscrowSearch} />
        <Route path="/escrow-file/:id" component={EscrowConditions} />

        {/* RESOURCES */}
        <Redirect exact from="/tools" to="/pricing" />
        {!LoginState.limited && (
          <Route path="/tools/pricing" component={Pricing} />
        )}
        <Route path="/tools/appraisal-finder" component={AppraisalFinder} />
        <Route exact path="/resources" component={Resources} />
        <Route
          exact
          path="/resources/product-matrix"
          component={ProductMatrix}
        />
        <Route exact path="/resources/forms" component={Forms} />
        {!LoginState.limited && (
          <Route exact path="/resources/daily-rates" component={DailyRates} />
        )}
        <Route
          exact
          path="/resources/geoid-validator"
          component={GeocodeFinder}
        />

        <Route
          exact
          path="/wizardsubmission/:id"
          component={WizardSubmission}
        />
        <Route exact path="/settings/:tab" component={Settings} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/adminmessage" component={AdminMessage} />
        <Route path="/pdf" component={UploadPdf} />
        <Route path="/download" component={DownloadFile} />
        <Route exact path="/redirect/:id" component={PageRedirect} />
        <Route exact path="/compare-programs" component={ProgramComparison} />
        <Route path="/restricted" component={Restricted} />
        <Redirect from="*" to="/dashboard" />
      </Switch>
      {!location.pathname.includes('details') && <Footer />}
    </div>
  );
};
export default AuthedRoutes;
