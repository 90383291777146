import React, { Component } from 'react';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import ViewElement from '../ViewElement';
import LoginState from '../LoginState';
import axios from 'axios';

class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: this.props.step,
      loan: this.props.loan,
      fnmFile: this.props.fnmFile,
      program: this.props.program,
      selectedValue: this.props.contacts
        ? this.props.contacts.selectedValue
        : '',
      selectedValue2: this.props.contacts
        ? this.props.contacts.selectedValue2
        : '',
      userRole: this.props.contacts
        ? {
            roleName: this.props.contacts.role,
            fullName: this.props.contacts.fullName,
            otherRoles: this.props.contacts.otherRoles,
            processors: this.props.contacts.processors,
          }
        : '',
      userName: this.props.contacts ? this.props.contacts.userName : '',
      roleEmail: this.props.contacts ? this.props.contacts.email : '',
      rolePhone: this.props.contacts ? this.props.contacts.phone : '',
      otherName: this.props.contacts ? this.props.contacts.otherName : '',
      otherUserName: this.props.contacts
        ? this.props.contacts.otherUserName
        : '',
      otherEmail: this.props.contacts ? this.props.contacts.otherEmail : '',
      otherPhone: this.props.contacts ? this.props.contacts.otherPhone : '',
      processor2Name: this.props.contacts
        ? this.props.contacts.processor2Name
        : '',
      processor2UserName: this.props.contacts
        ? this.props.contacts.processor2UserName
        : '',
      processor2Email: this.props.contacts
        ? this.props.contacts.processor2Email
        : '',
      processor2Phone: this.props.contacts
        ? this.props.contacts.processor2Phone
        : '',

      email0:
        this.props.contacts && this.props.contacts.borrowerCount > 0
          ? this.props.contacts.borrower0.email
          : this.props.loan.borrowers[0].email,
      phone0:
        this.props.contacts && this.props.contacts.borrowerCount > 0
          ? this.props.contacts.borrower0.phone
          : this.props.loan.borrowers[0].phone,

      // not sure how to conditionally set the values below if
      // additional borrowers don't exist. just setting with a
      // check on the value coming in, and if the additional
      // borrower(s) don't exist, the values just don't get used
      email1:
        this.props.contacts && this.props.contacts.borrowerCount > 1
          ? this.props.contacts.borrower1.email
          : this.props.loan.borrowers.length > 1
          ? this.props.loan.borrowers[1].email
          : '',
      phone1:
        this.props.contacts && this.props.contacts.borrowerCount > 1
          ? this.props.contacts.borrower1.phone
          : this.props.loan.borrowers.length > 1
          ? this.props.loan.borrowers[1].phone
          : '',

      email2:
        this.props.contacts && this.props.contacts.borrowerCount > 2
          ? this.props.contacts.borrower2.email
          : this.props.loan.borrowers.length > 2
          ? this.props.loan.borrowers[2].email
          : '',
      phone2:
        this.props.contacts && this.props.contacts.borrowerCount > 2
          ? this.props.contacts.borrower2.phone
          : this.props.loan.borrowers.length > 2
          ? this.props.loan.borrowers[2].phone
          : '',

      email3:
        this.props.contacts && this.props.contacts.borrowerCount > 3
          ? this.props.contacts.borrower3.email
          : this.props.loan.borrowers.length > 3
          ? this.props.loan.borrowers[3].email
          : '',
      phone3:
        this.props.contacts && this.props.contacts.borrowerCount > 3
          ? this.props.contacts.borrower3.phone
          : this.props.loan.borrowers.length > 3
          ? this.props.loan.borrowers[3].phone
          : '',
    };

    // get logged-in user data as LO or Processor
    if (!this.props.contacts) this.getUserRoleData();
  }

  async getUserRoleData() {
    // get all user data from the server and put into right format
    const response = axios.get('/getuserroledata', {
      params: { pUserID: LoginState.userId },
    });

    // the response.data will contain the values returned
    var resultData = (await response).data;
    this.setState({
      userRole: resultData,
      userName: resultData.userName,
      roleEmail: resultData.email,
      rolePhone: resultData.phone,
    });
  }

  gotoReviewPage() {
    let contacts = {};
    const numBorrowers = this.state.loan.borrowers.length;
    contacts.borrowerCount = numBorrowers;
    for (var i = 0; i < numBorrowers; i++) {
      contacts['borrower' + i] = {};
      contacts['borrower' + i].email = this.state['email' + i];
      contacts['borrower' + i].phone = this.state['phone' + i];
    }

    contacts.selectedValue = this.state.selectedValue;
    contacts.selectedValue2 = this.state.selectedValue2;
    contacts.role = this.state.userRole.roleName;
    contacts.fullName = this.state.userRole.fullName;
    contacts.userName = this.state.userName;
    contacts.otherRoles = this.state.userRole.otherRoles;
    contacts.processors = this.state.userRole.processors;
    contacts.email = this.state.roleEmail;
    contacts.phone = this.state.rolePhone;
    contacts.otherName = this.state.otherName;
    contacts.otherUserName = this.state.otherUserName;
    contacts.otherEmail = this.state.otherEmail;
    contacts.otherPhone = this.state.otherPhone;
    contacts.processor2Name = this.state.processor2Name;
    contacts.processor2UserName = this.state.processor2UserName;
    contacts.processor2Email = this.state.processor2Email;
    contacts.processor2Phone = this.state.processor2Phone;

    this.props.changeStep(
      'review',
      this.props.loan,
      this.props.fnmFile,
      null,
      contacts
    );
  }

  captureInput = e => {
    if (this.state.errors) {
      let errors = { ...this.state.errors };
      delete errors[e.target.id];
      this.setState({
        [e.target.id]: e.target.value,
        changesExist: true,
        errors,
      });
    } else {
      this.setState({
        [e.target.id]: e.target.value,
        changesExist: true,
      });
    }
  };

  updateFields = e => {
    for (var i = 0; i < this.state.userRole.otherRoles.length; i++) {
      if (
        parseInt(this.state.userRole.otherRoles[i].value) ===
        parseInt(e.target.value)
      ) {
        this.setState({
          selectedValue: e.target.value,
          otherName: this.state.userRole.otherRoles[i].fullName,
          otherEmail: this.state.userRole.otherRoles[i].email,
          otherPhone: this.state.userRole.otherRoles[i].phone,
          otherUserName: this.state.userRole.otherRoles[i].userName,
        });
        break;
      }
    }
  };

  updateFields2 = e => {
    for (var i = 0; i < this.state.userRole.processors.length; i++) {
      if (
        parseInt(this.state.userRole.processors[i].value) ===
        parseInt(e.target.value)
      ) {
        this.setState({
          selectedValue2: e.target.value,
          processor2Name: this.state.userRole.processors[i].fullName,
          processor2Email: this.state.userRole.processors[i].email,
          processor2Phone: this.state.userRole.processors[i].phone,
          processor2UserName: this.state.userRole.processors[i].userName,
        });
        break;
      }
    }
  };

  render() {
    if (!this.state.userRole) {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="/images/spinner.gif" alt="" height="64px" width="64px" />
        </div>
      );
    }

    const isLO = this.state.userRole.roleName === 'Loan Officer';

    return (
      <div className="col-12">
        <div className="row">
          <div className="text-center">
            <h5>
              Please provide contact information for all the involved parties
            </h5>
          </div>
          {/* ----- Borrowers Section ----- */}
          <div className="col-12">
            <h3 className="border-bottom-2 border-gray text-capitalize my-4">
              Borrowers
            </h3>
          </div>
          {this.state.loan.borrowers.map((borrower, index) => (
            <React.Fragment key={`${borrower.firstName}-${index}`}>
              <div className="col-12 col-md-4">
                <ViewElement
                  label="Borrower Name"
                  value={`${borrower.firstName} ${borrower.lastName}`}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  name={'email' + index}
                  label="Email Address"
                  type="email"
                  value={this.state['email' + index]}
                  onChange={this.captureInput}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  name={'phone' + index}
                  label="Phone Number"
                  type="telephone"
                  value={this.state['phone' + index]}
                  onChange={this.captureInput}
                />
              </div>
            </React.Fragment>
          ))}
          {/* ----- Loan Originator Section ----- */}
          <div className="col-12">
            <h3 className="border-bottom-2 border-gray text-capitalize my-4">
              Loan Originator
            </h3>
          </div>
          {isLO ? (
            <>
              <div className="col-12 col-md-4">
                <ViewElement
                  label="Loan Originator Name"
                  value={this.state.userRole.fullName}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="roleEmail"
                  label="Email Address"
                  type="email"
                  name="roleEmail"
                  value={this.state.roleEmail}
                  onChange={this.captureInput}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="rolePhone"
                  label="Phone Number"
                  type="telephone"
                  name="rolePhone"
                  value={this.state.rolePhone}
                  onChange={this.captureInput}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-md-4">
                <FormSelect
                  name="officers"
                  label="Loan Officers"
                  onChange={this.updateFields}
                  value={this.state.selectedValue}
                  options={this.state.userRole.otherRoles}
                ></FormSelect>
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="otherEmail"
                  label="Email Address"
                  type="email"
                  value={this.state.otherEmail}
                  onChange={this.captureInput}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="otherPhone"
                  label="Phone Number"
                  type="telephone"
                  value={this.state.otherPhone}
                  onChange={this.captureInput}
                />
              </div>
            </>
          )}
          {/* ----- Processor #1 Section ----- */}
          <div className="col-12">
            <h3 className="border-bottom-2 border-gray text-capitalize my-4">
              Loan Processor #1
            </h3>
          </div>
          {!isLO ? (
            <>
              <div className="col-12 col-md-4">
                <ViewElement
                  label="processor name"
                  value={this.state.userRole.fullName}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="roleEmail"
                  label="Email Address"
                  type="email"
                  value={this.state.roleEmail}
                  onChange={this.captureInput}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="rolePhone"
                  label="Phone Number"
                  type="telephone"
                  value={this.state.rolePhone}
                  onChange={this.captureInput}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-md-4">
                <FormSelect
                  name="processors"
                  label="Loan Processors"
                  onChange={this.updateFields}
                  value={this.state.selectedValue}
                  options={this.state.userRole.otherRoles}
                ></FormSelect>
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="otherEmail"
                  label="Email Address"
                  type="email"
                  name="otherEmail"
                  value={this.state.otherEmail}
                  onChange={this.captureInput}
                />
              </div>
              <div className="col-12 col-md-4">
                <FormInput
                  id="otherPhone"
                  label="Phone Number"
                  type="telephone"
                  name="otherPhone"
                  value={this.state.otherPhone}
                  onChange={this.captureInput}
                />
              </div>
            </>
          )}
          {/* ----- Processor #2 Section ----- */}
          <div className="col-12">
            <h3 className="border-bottom-2 border-gray text-capitalize my-4">
              Loan Processor #2
            </h3>
          </div>
          <div className="col-12 col-md-4">
            <FormSelect
              name="processors2"
              label="Loan Processors"
              onChange={this.updateFields2}
              value={this.state.selectedValue2}
              options={this.state.userRole.processors}
            ></FormSelect>
          </div>
          <div className="col-12 col-md-4">
            <FormInput
              id="processor2Email"
              label="Email Address"
              type="email"
              name="processor2Email"
              value={this.state.processor2Email}
              onChange={this.captureInput}
            />
          </div>
          <div className="col-12 col-md-4">
            <FormInput
              id="processor2Phone"
              label="Phone Number"
              type="telephone"
              name="processor2Phone"
              value={this.state.processor2Phone}
              onChange={this.captureInput}
            />
          </div>
          <div className="col-12 text-end mt-4">
            <button
              className="btn btn-light"
              onClick={() =>
                this.props.changeStep(
                  'select',
                  this.props.loan,
                  this.props.fnmFile,
                  null
                )
              }
            >
              Go Back
            </button>
            <button
              className="btn btn-primary text-white ms-2"
              onClick={() => this.gotoReviewPage()}
            >
              CONTINUE TO STEP 4
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
