import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoginState from './LoginState';
import AppraisalReceipt from './FileDetails/AppraisalReceipt';

export default class AppraisalOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retrievedData: false,
      searchLog: [],
      orders: [],
      showMessage: false,
    };
  }

  componentDidMount() {
    this.PageLoad(this.props.match.params.id);
  }

  async GetSearchLog(fileID) {
    // now update server with this data
    const response = axios.get('/getUpdatedSearchLog', {
      params: {
        pFileID: fileID,
        pUserID: LoginState.userId,
      },
    });
    var resultData = (await response).data;
    this.setState({ searchLog: resultData });
  }

  PageLoad(fileID) {
    this.setState({ orders: [], retrievedData: false });
    this.GetSearchLog(fileID);
    this.getAppraisalData(fileID);
  }

  async getAppraisalData(fileID) {
    axios
      .get('/getAppraisalOrders', {
        params: {
          pFileID: fileID,
        },
      })
      .then(res => {
        if (res && res.data) {
          this.setState({ orders: res.data, retrievedData: true });
        }
      })
      .catch(e => console.log(e, 'Error fetching loan details'));
  }

  render() {
    if (!this.state.retrievedData) {
      return <div className="container" />;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex gap-2 align-items-center mb-2">
            <h1 className="h4 text-uppercase">appraisal orders</h1>
            <button
              className="btn btn-text dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-list"></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <p className="dropdown-item mb-0 disabled">Recent Files</p>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {this.state.searchLog.length > 0 ? (
                <>
                  {this.state.searchLog.map(file => (
                    <li key={file.encodedFileID}>
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          this.props.history.push(
                            '/pipeline/appraisal/' + file.encodedFileID
                          );
                          this.PageLoad(file.encodedFileID);
                        }}
                      >
                        {file.encodedFileID} ({file.borrower})
                      </span>
                    </li>
                  ))}
                </>
              ) : (
                <li>
                  <p className="dropdown-item mb-0 disabled">No Recent Files</p>
                </li>
              )}
            </ul>
            <button
              className="btn btn-primary ms-auto"
              onClick={() => {
                window.open(
                  'https://1StopAMC.com/request/' +
                    LoginState.userName +
                    '-' +
                    this.props.match.params.id
                );
                this.setState({ showMessage: true });
              }}
            >
              Order New Appraisal
            </button>
          </div>
          {this.state.showMessage && (
            <div className="col-12">
              <div className="alert alert-info" role="alert">
                <i className="bi bi-exclamation-circle-fill text-info me-3 fs-5"></i>
                Please refresh this page after submitting an appraisal through
                1stopamc to show the new order
              </div>
            </div>
          )}
          <div className="col-12">
            {this.state.orders.length > 0 && (
              <div className="table-responsive bg-white rounded">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Order Date</th>
                      <th>Last Updated</th>
                      <th className="text-center">Appraisal Type</th>
                      <th className="text-center">Ordered By</th>
                      <th className="text-center">Estimated Fee</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orders.map(order => (
                      <tr key={order.orderID}>
                        {LoginState.userOrg === '1000001' &&
                        !LoginState.userRole
                          .toLowerCase()
                          .includes('account executive') ? (
                          <td className="align-middle">
                            <Link
                              color="primary500"
                              to={`../../details/apr-${this.props.match.params.id}-${order.index}`}
                            >
                              {order.orderID}
                            </Link>
                          </td>
                        ) : (
                          <td className="align-middle">{order.orderID}</td>
                        )}
                        <td className="align-middle">{order.orderDate}</td>
                        <td className="align-middle">{order.lastUpdated}</td>
                        <td className="align-middle text-center">
                          {order.orderType}
                        </td>
                        <td className="align-middle text-center">
                          {order.orderedBy}
                        </td>
                        <td className="align-middle text-center">
                          {order.orderFee}
                        </td>
                        <td className="align-middle text-center">
                          {order.orderStatus}
                        </td>
                        <td className="text-center">
                          {order.invoiceReady && (
                            <AppraisalReceipt
                              smallDisplay={true}
                              orderID={order.orderID}
                              fee={order.paymentLinkFee}
                              style={{ maxWidth: '200px' }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
